import { apiPrivatePythonClient } from './axios';
import { LawyerMember } from './lawyerMember';

export const DEFAULT_QUESTIONS_PAGE_SIZE = 10;

export type RequestStatus =
  | 'processing'
  | 'awaiting_answer'
  | 'answered'
  | 'archived'
  | 'consultation_scheduled'
  | 'consultation_to_reschedule'
  | 'consultation_completed'
  | 'consultation_cancelled';

export type QuestionStatus =
  | 'awaiting_payment'
  | 'awaiting_manual_check'
  | 'pending_qualification'
  | 'assigned'
  | 'assignment_rejected'
  | 'assignment_accepted'
  | 'answered'
  | 'answer_validated'
  | 'additional_request_in_progress'
  | 'additional_request_answered'
  | 'reviewed'
  | 'archived';

type Tag = {
  id: string;
  name: string;
};

export interface UnassignedQuestion {
  id: number;
  content: string;
  title: string;
  created_at: string;
  assigned_at: string | null;
  status: QuestionStatus;
  request_status: RequestStatus;
  tag: Tag | null;
  law_domain_id: string | null;
  tag_id: string | null;
  remaining_time_to_answer_seconds: number | null;
  answer_deadline_at: string;
  can_accept_reject: boolean;
  member: LawyerMember;
}

export interface LawyerQuestion extends UnassignedQuestion {
  ai_suggestion: string | null;
  consultation_id: number | null;
  note: number | null;
  can_answer: boolean;
  answer_id: number | null;
}

export type GetLawyerQuestionsPayload = {
  status?: QuestionStatus[];
  content?: string | null;
  page?: number;
  pageSize?: number;
  memberId?: number;
  tagId?: number;
  orderBy?: '-created_at' | '+created_at';
};

export type GetLawyerSimilarQuestionsPayload = {
  questionId: number;
};

export type GetLawyerQuestionsResponse = {
  total: number;
  items: Array<LawyerQuestion | UnassignedQuestion>;
};

export type GetLawyerSimilarQuestionsResponse = {
  questions: LawyerQuestion[];
};

export async function getLawyerQuestions({
  status,
  memberId,
  tagId,
  page = 1,
  content,
  pageSize = DEFAULT_QUESTIONS_PAGE_SIZE,
  orderBy = '+created_at'
}: GetLawyerQuestionsPayload): Promise<GetLawyerQuestionsResponse> {
  const { data } = await apiPrivatePythonClient.get<GetLawyerQuestionsResponse>(
    '/lawyer/questions',
    {
      params: {
        status,
        content,
        member_id: memberId,
        tag_id: tagId,
        page,
        page_size: pageSize,
        order_by: orderBy
      }
    }
  );

  return data;
}

export async function getLawyerQuestion(
  questionId: number
): Promise<LawyerQuestion> {
  const { data } = await apiPrivatePythonClient.get<LawyerQuestion>(
    `/lawyer/questions/${questionId}`
  );

  return data;
}

export async function getLawyerSimilarQuestions({
  questionId
}: GetLawyerSimilarQuestionsPayload): Promise<GetLawyerSimilarQuestionsResponse> {
  const { data } =
    await apiPrivatePythonClient.get<GetLawyerSimilarQuestionsResponse>(
      `/lawyer/questions/${questionId}/recommendations`
    );

  return data;
}
