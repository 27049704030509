import { Card, Divider, Tag, Title } from 'components';
import ArticleHeader from 'components/article/ArticleHeader';
import { CardContent, CardImage } from 'components/card';
import AlphabetSheetSkeleton from 'components/skeleton/AlphabetSheetSkeleton';
import { useTranslation } from 'react-i18next';
import { useGetArticleBySlug } from 'services/dato/articles';

import { formatDateToIntl } from 'utils/date';

interface ArticleProps {
  slug: string;
}

const Article = ({ slug }: ArticleProps) => {
  const { t } = useTranslation('customer');
  const { data, isLoading, isSuccess } = useGetArticleBySlug(slug);

  return (
    <>
      {isLoading && <AlphabetSheetSkeleton />}
      {isSuccess && data && (
        <>
          <Card
            compact
            className="flex rounded-2xl xl:min-h-screen w-full xl:w-72"
          >
            <div className="flex flex-row xl:flex-col">
              <CardImage
                className="w-28 md:w-52 xl:w-full h-40"
                imageAttributes={data.article.featuredMedia.responsiveImage}
              />

              <div className="ml-6 xl:ml-0 mt-0 xl:mt-6 self-end xl:self-start">
                <Tag>{data.article.tags[0].name}</Tag>
              </div>
            </div>
            <ArticleHeader
              readingTime="5"
              author={data.article.author}
              publishedAt={t('account.articles.list.card.publishedAt', {
                date: formatDateToIntl(data.article.updatedAt)
              })}
            />
          </Card>
          <Card className="rounded-2xl flex-1 !p-6 min-h-screen">
            <Title variant="h1">{data.article.title}</Title>
            <CardContent className=" mt-8">
              <hr className="my-6" />
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: data?.article.content || ''
                }}
              />
            </CardContent>
            <Divider />
          </Card>
        </>
      )}
    </>
  );
};

export default Article;
