import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FullCard } from 'components';
import { ArticleListSkeleton } from 'components/skeleton';
import { formatDistanceToNow } from 'date-fns';
import { last } from 'lodash';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import {
  ARTICLE_BY_PAGE,
  useGetPaginatedArticles
} from 'services/dato/articles';
import { getAuthenticatedMemberInfo } from 'services/member';

import { useLocale } from 'hooks';

import { TextInputGroup } from 'features/shared/forms';

const ArticlesList = () => {
  const { dateLocale } = useLocale();
  const {
    register,
    formState: { errors },
    watch
  } = useForm<{
    search: string | null;
  }>({
    defaultValues: { search: '' }
  });
  const { data: member, isLoading: isMemberLoading } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  const { data, isLoading, fetchNextPage, isSuccess } = useGetPaginatedArticles(
    watch('search'),
    member?.is_active_cse_member,
    member?.has_labor_law_access,
    !!member?.id
  );

  return (
    <div>
      <div className="flex-1 self-end my-4 gap-4 flex-wrap w-full">
        <TextInputGroup
          type="string"
          label=""
          placeholder="Rechercher des articles..."
          name="search"
          register={register}
          error={errors.search}
        />
      </div>
      {isLoading || isMemberLoading || !isSuccess ? (
        <ArticleListSkeleton number={12} />
      ) : (
        <InfiniteScroll
          loader={<ArticleListSkeleton number={12} />}
          dataLength={data.pages.length * ARTICLE_BY_PAGE}
          next={fetchNextPage}
          hasMore={
            (data.pages[0]?.allArticlesMeta.count || 1) >
            (last(data.pageParams as number[]) || 1)
          }
        >
          <div className="grid sm:grid-cols-4 xl:grid-cols-3 gap-5 mb-10">
            {data?.pages.map((page) =>
              page?.allArticles.map((article, index) => (
                <Link
                  key={article.id}
                  to={`/account/resources/${article.slug}`}
                  className={classNames('grid', {
                    'sm:col-span-4 lg:col-span-1': index === 0,
                    'sm:col-span-2 lg:col-span-1': index !== 0
                  })}
                >
                  <FullCard
                    imgHeight="h-48"
                    title={article.title}
                    image={article.featuredMedia?.responsiveImage}
                    tags={[article.tags[0]?.name]}
                    publishedAt={formatDistanceToNow(
                      new Date(article.updatedAt),
                      {
                        addSuffix: true,
                        locale: dateLocale
                      }
                    )}
                  />
                </Link>
              ))
            )}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ArticlesList;
