import {
  CalendarIcon,
  CheckCircleIcon,
  TagIcon,
  UserIcon
} from '@heroicons/react/solid';
import { Tag, Title } from 'components';
import { Card, CardContent } from 'components/card';
import { RatingStars } from 'components/statistic';
import { useTranslation } from 'react-i18next';
import { LawyerQuestion } from 'services/lawyerQuestion';

import { formatDateToIntl } from 'utils/date';

import AnswerDeadline from './AnswerDeadline';

interface LawyerQuestionOverviewProps {
  question: LawyerQuestion;
}

const LawyerQuestionOverview = ({ question }: LawyerQuestionOverviewProps) => {
  const { t } = useTranslation('lawyer');

  return (
    <Card highlight>
      <CardContent className="mb-1">
        <div className="flex gap-2 items-center">
          <Title variant="h3" className="!font-bold">
            {question.title}
          </Title>
          {question.request_status !== 'awaiting_answer' && (
            <Tag
              className="text-xs ml-2 hidden lg:block"
              variant={
                ['archived', 'consultation_cancelled'].includes(
                  question.request_status
                )
                  ? 'error'
                  : 'success'
              }
            >
              {t(`account.lawyer.requests.status.${question.request_status}`)}
            </Tag>
          )}
        </div>
      </CardContent>
      <CardContent className="!flex-row items-center text-base font-medium gap-4 text-gray-800">
        <div className="flex items-center">
          <UserIcon className="size-5 mr-1.5 text-gray-600" />
          {question.member.full_name}
        </div>
        {question.note && (
          <div className="flex items-center">
            <CheckCircleIcon className="size-5 mr-1.5 text-gray-600" />
            <RatingStars value={question.note} />
          </div>
        )}
        {question.tag && (
          <div className="flex items-center">
            <TagIcon className="size-5 mr-1.5 text-gray-600" />
            {question.tag.name}
          </div>
        )}
        <div className="flex items-center">
          {question.status === 'assignment_accepted' ? (
            <AnswerDeadline
              displayDate
              answerDeadLine={question.answer_deadline_at}
            />
          ) : (
            <>
              <CalendarIcon className="size-5 mr-1.5 text-gray-600" />
              {t('account.lawyer.requests.askedAt', {
                date: formatDateToIntl(new Date(question.created_at), 'long')
              })}
            </>
          )}
        </div>
      </CardContent>
      <Tag
        className="text-xs mb-1 block lg:hidden"
        variant={
          ['archived', 'consultation_cancelled'].includes(
            question.request_status
          )
            ? 'error'
            : 'success'
        }
      >
        {t(`account.lawyer.requests.status.${question.request_status}`)}
      </Tag>
    </Card>
  );
};

export default LawyerQuestionOverview;
