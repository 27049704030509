import { UserGroupIcon } from '@heroicons/react/outline';
import { Title } from 'components';
import { Card, CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';

const LawyerEmptyAssignedQuestions = () => {
  const { t } = useTranslation('lawyer');

  return (
    <Card>
      <CardContent className="">
        <div className="flex flex-col max-w-96 items-center gap-4 pb-10 self-center">
          <UserGroupIcon className="size-10" />
          <Title
            variant="h3"
            component="h2"
            className="!font-bold text-center !mb-0"
          >
            {t('account.lawyer.requests.assignation.empty.title')}
          </Title>
        </div>
      </CardContent>
    </Card>
  );
};

export default LawyerEmptyAssignedQuestions;
