import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import moment from 'moment';
import 'moment/locale/fr';
import { useState } from 'react';
import Datetime from 'react-datetime';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  AddBulkPartnerCollaboratorInput,
  addBulkCollaborators
} from 'services/admin/members';

import { DocumentDropzone } from 'features/customer/account/consultations/booking';
import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { CheckboxInputGroup } from 'features/shared/forms';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

import { getAuthenticatedMemberInfo } from '../../../services/member';
import PartnerCollaboratorBulkResume from './PartnerCollaboratorBulkReport';

const today = moment();

const PartnerCollaboratorBulkAdd = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('customer');
  const { data: member } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    setError,
    handleSubmit,
    register,
    reset: resetForm,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm<AddBulkPartnerCollaboratorInput>({
    defaultValues: { step: 1, withOnboardingEmail: false }
  });

  const currentStep = watch('step');
  const withOnboardingEmail = watch('withOnboardingEmail');
  const {
    mutate,
    data,
    isSuccess,
    isPending,
    reset: resetMutation
  } = useMutation({
    mutationFn: (formData: AddBulkPartnerCollaboratorInput) =>
      addBulkCollaborators(member!.customer_id!, formData),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['customer-members'] }),
    onError: (error: any) => {
      const { data: errorData } = error.response;
      if (errorData.error.code === 'media_type_not_allowed') {
        setError('file' as any, {
          message: t('admin.collaborators.form.bulk.error.badFile')
        });
      }

      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('admin.collaborators.form.add.error.generic')}
        />,
        { position: 'top-right', duration: 3000 }
      );
    }
  });

  return (
    <div>
      <Button
        variant="outline"
        size="large"
        fullWidth
        onClick={() => {
          setIsModalOpen(true);
          resetForm();
          resetMutation();
        }}
        label={t('admin.collaborators.button.bulk')}
      />
      <LayoutModal
        isModalOpen={isModalOpen}
        handleModalClose={() => setIsModalOpen(false)}
      >
        <Title
          className="!font-bold"
          variant="h3"
          text="Ajouter des collaborateurs"
        />
        {isSuccess && (
          <div className="flex flex-col gap-2 my-5">
            <PartnerCollaboratorBulkResume
              title={t(
                'admin.collaborators.form.bulk.success.nbAddedCollaborators.title'
              )}
              result={data.addedCollaboratorResult}
            />
            <PartnerCollaboratorBulkResume
              title={t(
                'admin.collaborators.form.bulk.success.nbArchivedCollaborators.title'
              )}
              result={data.archivedCollaboratorResult}
            />
            <Paragraph className="text-sm text-gray-550 mt-2">
              {t('admin.collaborators.form.bulk.success.description')}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                  resetForm();
                  resetMutation();
                }}
                isLoading={isPending}
                variant="tertiary"
                size="medium"
                label={t('general.close')}
              />
            </div>
          </div>
        )}
        {!isSuccess && (
          <form
            className="flex flex-col gap-5 my-5"
            onSubmit={handleSubmit(
              (formData: AddBulkPartnerCollaboratorInput) => mutate(formData)
            )}
          >
            {currentStep === 1 && (
              <>
                <div>
                  <Paragraph size="sm">
                    {t('admin.collaborators.form.bulk.step1.description1')}
                  </Paragraph>
                  <Paragraph size="sm" className="text-gray-500 mt-4">
                    {t('admin.collaborators.form.bulk.step1.description2')}
                  </Paragraph>
                </div>
                <div className="flex gap-4 justify-end mt-2">
                  <Button
                    onClick={() => setValue('step', 2)}
                    variant="secondary"
                    size="medium"
                    label={t('admin.collaborators.form.bulk.button.next')}
                  />
                  <Button
                    isLoading={isPending}
                    variant="tertiary"
                    size="medium"
                    onClick={() => {
                      const a = document.createElement('a');
                      a.href = '/template/template-collaborators.xlsx';
                      a.download =
                        '/template/template-collaborators.xlsx'
                          .split('/')
                          .pop() || '';
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                      setValue('step', 2);
                    }}
                    label={t(
                      'admin.collaborators.form.bulk.button.downloadTemplate'
                    )}
                  />
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <div>
                  <Paragraph size="sm">
                    {t('admin.collaborators.form.bulk.step2.description')}
                  </Paragraph>
                  <div className="flex flex-col gap-6 mt-6">
                    <DocumentDropzone
                      required
                      label=""
                      typeLabel="file.upload.4"
                      document={watch('file')}
                      setDocument={(file) => setValue('file', file)}
                    />
                    <div className="text-left text-sm text-red-800">
                      {errors.file?.message}
                    </div>
                    <div className="flex-col flex gap-3">
                      <CheckboxInputGroup
                        name="withOnboardingEmail"
                        register={register}
                        error={errors.withOnboardingEmail}
                        label={t(
                          'admin.collaborators.form.bulk.withOnboardingEmail.label'
                        )}
                      />
                      {withOnboardingEmail && (
                        <Controller
                          name="sendOnboardingEmailDate"
                          control={control}
                          defaultValue={moment()}
                          render={({ field: { onChange } }) => (
                            <Datetime
                              className="rdtPickerOpenUpwards"
                              onChange={onChange}
                              initialValue={moment()}
                              input={false}
                              closeOnSelect
                              closeOnClickOutside
                              isValidDate={(current: any) =>
                                current.isAfter(today)
                              }
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 justify-end mt-2">
                  <Button
                    onClick={() => setValue('step', 1)}
                    variant="secondary"
                    size="medium"
                    label={t('admin.collaborators.form.bulk.button.previous')}
                  />
                  <Button
                    submit
                    isLoading={isPending}
                    variant="custom"
                    size="medium"
                    label={t('admin.collaborators.form.bulk.button.submit')}
                    customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
                  />
                </div>
              </>
            )}
          </form>
        )}
      </LayoutModal>
    </div>
  );
};

export default PartnerCollaboratorBulkAdd;
