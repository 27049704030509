import classNames from 'classnames';
import { intervalToDuration, isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ClockIcon } from 'features/shared/icons';

import { getDateAsDdMmYyyy, getDateAsHhMm } from 'utils/date';

import { LawyerQuestion } from '../../../services/lawyerQuestion';

interface AnswerDeadlineProps {
  answerDeadLine: LawyerQuestion['answer_deadline_at'];
  displayDate?: boolean;
}

const getDurationColor = (hour?: number | undefined) => {
  if (!hour) return { className: 'text-red-800', color: '#EC595B' };
  if (hour < 3) return { className: 'text-red-800', color: '#EC595B' };
  if (hour < 12) return { className: 'text-orange-800', color: '#f97316' };

  return { className: 'text-green-800', color: '#56A663' };
};
const AnswerDeadline = ({
  answerDeadLine,
  displayDate = false
}: AnswerDeadlineProps) => {
  const { t } = useTranslation('lawyer');
  const day = getDateAsDdMmYyyy(new Date(answerDeadLine));
  const time = getDateAsHhMm(new Date(answerDeadLine));

  const isAnswerOverdue = isPast(new Date(answerDeadLine));
  const durationLeft = intervalToDuration({
    end: new Date(),
    start: new Date(answerDeadLine)
  });

  const durationColor = isAnswerOverdue
    ? getDurationColor()
    : getDurationColor(durationLeft.hours);

  return (
    <div className="flex">
      <div className="flex flex-col md:flex-row gap-2 font-medium">
        <div
          className={classNames(durationColor.className, 'flex items-center')}
        >
          <div className="pr-2">
            <ClockIcon fill={durationColor.color} />
          </div>
          {isAnswerOverdue
            ? t('account.questions.timeOverdue.label', {
                count: durationLeft.days || 0,
                hours: durationLeft.hours,
                minutes: durationLeft.minutes
              })
            : t('account.questions.timeLeft.label', {
                count: durationLeft.days || 0,
                hours: durationLeft.hours,
                minutes: durationLeft.minutes
              })}
        </div>
        <div className="flex justify-start">
          <span className="text-gray-700 text-sm leading-6">
            {t('account.questions.answerDeadline.label', {
              day,
              time
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AnswerDeadline;
