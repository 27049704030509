import { Outlet } from 'react-router-dom';

import { LawyerMenu } from 'features/menu';

export default function AccountPage() {
  return (
    <div className="min-h-screen bg-gray-100 md:grid md:grid-cols-account">
      <div
        id="sidebar"
        className="md:py-4 md:pl-6 md:col-span-2 bg-white md:border-r md:border-gray-600"
      >
        <LawyerMenu />
      </div>
      <div id="content" className="mt-[72px] md:mt-0 md:col-span-9">
        <Outlet />
      </div>
    </div>
  );
}
