import { useTranslation } from 'react-i18next';

import { MemberSubscriptionInfo } from '../../../../services/member';
import SectionLine from '../dashboard/SectionLine';

const getPerMonthLabel = (maxNb: number | null) => {
  if (maxNb === 0) return 'general.none';
  if (maxNb === null) return 'general.unlimited';

  return 'subscription.restrictions.max';
};

export interface SubscriptionSectionProps {
  subscription: MemberSubscriptionInfo;
}

export default function SubscriptionDetails({
  subscription
}: SubscriptionSectionProps) {
  const { t } = useTranslation('customer');

  return (
    <div className="flex w-full flex-col space-y-2">
      <SectionLine
        title={t('general.subscription')}
        value={subscription.product_name}
      />
      <SectionLine
        title={t('account.subscription.restrictionQuestion')}
        value={t(getPerMonthLabel(subscription.product_max_total_questions), {
          total: subscription.product_max_total_questions
        })}
      />
      <SectionLine
        title={t('account.subscription.restrictionConsultations')}
        value={t(
          getPerMonthLabel(subscription.product_max_total_consultations),
          {
            total: subscription.product_max_total_consultations
          }
        )}
      />
    </div>
  );
}
