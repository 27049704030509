import { Title } from 'components';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getLawyerConsultations } from 'services/lawyerConsultation';

import {
  LawyerPastConsultations,
  LawyerScheduledConsultations,
  LawyerToCompleteConsultations
} from 'features/consultation';
import { ConsultationsNav } from 'features/consultation/components';

import { ConsultationStatus, ConsultationsFilter } from 'types/consultation';

const ConsultationsPage = () => {
  const [searchParams] = useSearchParams();
  const defaultStatus =
    (searchParams.get('tab') as ConsultationsFilter) ||
    ConsultationsFilter.SCHEDULED;

  const { data: consultationsScheduledResponse } = useQuery({
    queryKey: ['lawyer-consultations-scheduled'],
    queryFn: () =>
      getLawyerConsultations({
        completable: false,
        status: [ConsultationStatus.SCHEDULED]
      })
  });
  const { data: consultationsToCompleteResponse } = useQuery({
    queryKey: ['lawyer-consultations-to-complete'],
    queryFn: () =>
      getLawyerConsultations({
        completable: true,
        status: [ConsultationStatus.SCHEDULED]
      })
  });
  const [filter, setFilter] = useState<ConsultationsFilter>(defaultStatus);

  return (
    <div className="min-h-screen">
      <div className="bg-gray-paper z-10 pt-4 md:pt-12 pb-6">
        <div className="px-4 sm:px-12 lg:px-24">
          <div className="mb-4 md:mb-6">
            <Title variant="h1">Consultations</Title>
          </div>
          <ConsultationsNav
            filter={filter}
            setFilter={setFilter}
            scheduledLength={consultationsScheduledResponse?.total}
            toCompleteLength={consultationsToCompleteResponse?.total}
          />
        </div>
        <div className="mb-6 border-b border-gray-600" />
        <div className="px-4 sm:px-12 lg:px-24">
          {filter === ConsultationsFilter.SCHEDULED && (
            <LawyerScheduledConsultations />
          )}
          {filter === ConsultationsFilter.TOCOMPLETE && (
            <LawyerToCompleteConsultations />
          )}
          {filter === ConsultationsFilter.PAST && <LawyerPastConsultations />}
        </div>
      </div>
    </div>
  );
};

export default ConsultationsPage;
