import { PaperAirplaneIcon } from '@heroicons/react/outline';
import { useMutation } from '@tanstack/react-query';
import { Title } from 'components';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { sendOnboardingEmail } from 'services/admin/members';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

interface CustomerMemberSendOnboardingEmailProps {
  memberId: number;
  firstName: string;
  lastName: string;
}

const CustomerMemberSendOnboardingEmail = ({
  memberId,
  firstName,
  lastName
}: CustomerMemberSendOnboardingEmailProps) => {
  const { t } = useTranslation('customer');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isPending, isSuccess, isIdle, isError, reset } = useMutation({
    mutationFn: () => sendOnboardingEmail(memberId),
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('error.occurred')}
        />,
        { position: 'top-right', duration: 3000 }
      );
    }
  });

  const closeModal = () => {
    setIsModalOpen(false);
    reset();
  };

  return (
    <div>
      <Button
        variant="custom"
        size="small"
        customClassNames="!p-0 text-white"
        onClick={() => setIsModalOpen(true)}
        icon={
          <PaperAirplaneIcon
            className="w-5 h-6 rotate-90"
            strokeWidth={2}
            stroke="gray"
          />
        }
      />
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModal}>
        <Title variant="h3" text="Envoyer une invitation Ekie" />
        {isSuccess && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.form.sendInvitationEmail.success')}
            </Paragraph>
            <div className="flex justify-end">
              <Button
                variant="tertiary"
                size="medium"
                label={t('general.close')}
                onClick={closeModal}
              />
            </div>
          </div>
        )}
        {(isIdle || isPending || isError) && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.modal.sendInvitationEmail.text', {
                firstName,
                lastName
              })}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={closeModal}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                onClick={() => mutate()}
                isLoading={isPending}
                variant="custom"
                size="medium"
                label={t(
                  'admin.collaborators.modal.sendInvitationEmail.submit'
                )}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </div>
        )}
      </LayoutModal>
    </div>
  );
};

export default CustomerMemberSendOnboardingEmail;
