import Back from 'components/Back';
import { RatingStars } from 'components/statistic';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getLawyerQuestionAnswer } from 'services/answer';
import { getLawyerQuestion } from 'services/lawyerQuestion';

import { LawyerAnswerCard } from 'features/answer/components';
import { LawyerQuestionCard } from 'features/questions/components';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

const QuestionAnsweredPage = () => {
  const { questionId = '' } = useParams();
  const { t } = useTranslation('lawyer');
  const { data: answer } = useQuery({
    queryKey: ['lawyer-answer', questionId],
    queryFn: () => getLawyerQuestionAnswer(Number(questionId)),
    refetchOnWindowFocus: true
  });

  const { data: question } = useQuery({
    queryKey: ['lawyer-question', questionId],
    queryFn: () => getLawyerQuestion(Number(questionId))
  });

  if (!answer || !question) return <LayoutLoadingSkeleton />;

  return (
    <div className="overflow-y-auto max-h-screen">
      <Back label={t('account.lawyer.requests.toAnswer.back')} />
      <div className="container mx-auto">
        <div className="flex flex-col gap-4 px-6">
          <div>
            <LawyerQuestionCard question={question} />
          </div>
          <div>
            <LawyerAnswerCard answer={answer} />
          </div>
          {answer.note !== null && (
            <div className="self-end">
              <div className="italic text-sm text-gray-600">
                {answer.rating_comment}
              </div>
              <Trans
                parent="div"
                ns="lawyer"
                className="flex items-center text-sm font-semibold mt-1"
                i18nKey="account.questions.answered.votedBy"
                components={{
                  Stars: <RatingStars className="ml-2" value={answer.note} />
                }}
                values={{ fullName: question.member.full_name }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionAnsweredPage;
