import { Navigate, Route, Routes } from 'react-router-dom';

import { useLawyerQuery } from 'api/lawyer/lawyers';

import { CalendarPage, LawyerConsultationsPage } from 'pages/consultation';
import { NotFoundPage } from 'pages/error';
import {
  AccountPage,
  DashboardPage,
  LawyerCarePage,
  ProfilePage
} from 'pages/lawyer';
import {
  LawyerAssignationsPage,
  LawyerQuestionsToAnswerPage,
  QuestionAnsweredPage,
  QuestionToAnswerPage,
  RequestsPage
} from 'pages/questions';

import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { LAWYER_DEFAULT_REDIRECT_URL } from './constants';

const LawyerRouter = () => {
  const lawyer = useLawyerQuery();

  if (!lawyer.isSuccess) return <LayoutLoadingSkeleton />;

  return (
    <Routes>
      <Route
        path=""
        element={<Navigate to={LAWYER_DEFAULT_REDIRECT_URL} replace />}
      />
      <Route
        path="login"
        element={<Navigate to={LAWYER_DEFAULT_REDIRECT_URL} replace />}
      />
      <Route
        path="authenticate"
        element={<Navigate to={LAWYER_DEFAULT_REDIRECT_URL} replace />}
      />
      <Route path="account" element={<AccountPage />}>
        <Route path="calendar" element={<CalendarPage />} />
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route
          path="home"
          element={<Navigate replace to={LAWYER_DEFAULT_REDIRECT_URL} />}
        />
        <Route path="requests" element={<RequestsPage />} />
        <Route
          path="questions-assignation"
          element={<LawyerAssignationsPage />}
        />
        <Route
          path="questions-to-answer"
          element={<LawyerQuestionsToAnswerPage />}
        />
        <Route path="questions">
          <Route
            path="questions-to-answer/:questionId/*"
            element={<QuestionToAnswerPage />}
          />
          <Route
            path="questions-answered/:questionId"
            element={<QuestionAnsweredPage />}
          />
        </Route>
        <Route path="consultations" element={<LawyerConsultationsPage />} />
        <Route path="lawyercare" element={<LawyerCarePage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default LawyerRouter;
