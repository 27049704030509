import { ErrorBoundary } from '@sentry/react';
import GenericErrorCard from 'components/card/GenericErrorCard';

import { PageLayout } from 'features/customer/account/content';
import { LawyerQuestionsToAnswer } from 'features/questions';

const LawyerQuestionsToAnswerPage = () => (
  <PageLayout>
    <ErrorBoundary fallback={<GenericErrorCard />}>
      <LawyerQuestionsToAnswer />
    </ErrorBoundary>
  </PageLayout>
);

export default LawyerQuestionsToAnswerPage;
