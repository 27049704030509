import { ExclamationIcon } from '@heroicons/react/outline';
import { useQuery } from '@tanstack/react-query';
import { Section } from 'components';
import { Fade } from 'components/transition';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getLawyerConsultations } from 'services/lawyerConsultation';

import { ConsultationStatus, ConsultationsFilter } from 'types/consultation';

const ConsultationsToCompleteReminder = () => {
  const { t } = useTranslation('lawyer');
  const { data, isPending, isSuccess } = useQuery({
    queryKey: [
      'lawyer-consultations',
      { status: [ConsultationStatus.SCHEDULED], completable: true }
    ],
    queryFn: () =>
      getLawyerConsultations({
        page: 1,
        completable: true,
        status: [ConsultationStatus.SCHEDULED]
      })
  });

  if (isPending || !isSuccess || data.total === 0) return null;

  return (
    <Fade>
      <Link
        className="text-white hover:underline underline-offset-4"
        to={`/account/consultations?tab=${ConsultationsFilter.TOCOMPLETE}`}
      >
        <Section
          gutterBottom
          className="!py-2 !pl-4 !bg-purple-800/90 hover:shadow-sm"
        >
          <div className="flex items-center gap-2">
            <div className="text-white p-1 rounded-full">
              <ExclamationIcon className="size-5" />
            </div>
            {t('account.dashboard.reminder.toComplete', {
              count: data.total
            })}
          </div>
        </Section>
      </Link>
    </Fade>
  );
};

export default ConsultationsToCompleteReminder;
