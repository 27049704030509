import { useQuery } from '@tanstack/react-query';
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Pagination } from '../../../components/pagination';
import AdministratorListSkeleton from '../../../components/skeleton/AdministratorListSkeleton';
import {
  DEFAULT_MEMBERS_PAGE_SIZE,
  getAdminMembers
} from '../../../services/admin/administrators';
import { Member } from '../../../services/admin/members';
import { Toast } from '../../shared/toasts';

const PAGE_SIZE = DEFAULT_MEMBERS_PAGE_SIZE;

const AdministratorList = () => {
  const { t } = useTranslation('customer');

  const [searchParams] = useSearchParams();

  const pageIndex = Number(searchParams.get('page')) || 1;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex,
    pageSize: PAGE_SIZE
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ['customer-admins', pagination.pageIndex],
    queryFn: () => getAdminMembers(pagination.pageIndex, pagination.pageSize)
  });

  const columns = useMemo<ColumnDef<Member>[]>(
    () => [
      {
        accessorKey: 'fullName',
        cell: ({ row }) => row.original.full_name,
        header: () => t('admin.administrators.list.header.fullName')
      },
      {
        accessorKey: 'principalEmail',
        cell: ({ row }) => row.original.email,
        header: () => t('admin.administrators.list.header.email')
      }
    ],
    [t]
  );

  const table = useReactTable({
    data: data?.items ?? [],
    columns,
    pageCount: (data?.total || 0) / PAGE_SIZE,
    state: { pagination },
    enableRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    enableMultiRowSelection: false,
    getRowId: (row) => `${row.id}|${row.email}`
  });

  useEffect(() => {
    if (isError) {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('admin.administrators.error')}
        />,
        { position: 'top-right', duration: 3000 }
      );
    }
  }, [isError]);

  if (isLoading) return <AdministratorListSkeleton />;

  return (
    <>
      <table className="w-full table-auto">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={classNames(
                    'text-left text-gray-800 font-normal py-3 px-4'
                  )}
                >
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="font-medium rounded-md hover:bg-gray-200 border-y-2 border-gray-300"
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={classNames('py-3 px-4')}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classNames('flex w-full justify-center mt-6')}>
        <Pagination
          onRefetch={(page) =>
            setPagination({
              pageIndex: page,
              pageSize: PAGE_SIZE
            })
          }
          pageCount={Math.ceil(table.getPageCount())}
          initialPage={pageIndex}
        />
      </div>
    </>
  );
};

export default AdministratorList;
