import { useTranslation } from 'react-i18next';

import { Alert } from 'features/shared/alerts';

import { formatDateToIntl } from 'utils/date';

import { MemberSubscriptionInfo } from '../../../../services/member';

export function ActiveSubscriptionStatus({
  productName
}: {
  productName: string;
}) {
  const { t } = useTranslation('customer');

  return (
    <Alert type="success" title={t('account.subscription.active')}>
      <p>
        {t('account.subscription.active.paragraph', {
          productName
        })}
      </p>
    </Alert>
  );
}

export function ActiveTerminatedSubscriptionStatus({ endAt }: { endAt: Date }) {
  const { t } = useTranslation('customer');

  return (
    <Alert type="warning" title={t('account.subscription.active_terminated')}>
      <p>
        {t('account.subscription.active_terminated.paragraph', {
          endDate: formatDateToIntl(new Date(endAt!))
        })}
      </p>
    </Alert>
  );
}

export function TerminatedSubscriptionStatus({
  productName
}: {
  productName: string;
}) {
  const { t } = useTranslation('customer');

  return (
    <Alert type="warning" title={t('account.subscription.inactive')}>
      <p>
        {t('account.subscription.inactive.paragraph', {
          productName
        })}
      </p>
    </Alert>
  );
}

export default function SubscriptionStatus({
  subscription
}: {
  subscription: MemberSubscriptionInfo;
}) {
  const { status } = subscription;
  switch (status) {
    case 'active':
      return (
        <ActiveSubscriptionStatus productName={subscription.product_name} />
      );
    case 'active_terminated':
      return (
        <ActiveTerminatedSubscriptionStatus
          endAt={new Date(subscription.end_at!)}
        />
      );
    case 'terminated':
      return (
        <TerminatedSubscriptionStatus productName={subscription.product_name} />
      );
  }
}
