import { useMemo } from 'react';

export default function useCreateHref({
  path,
  params
}: {
  path: string;
  params: Record<string, any>;
}) {
  const searchParams = useMemo(
    () => new URLSearchParams({ ...params }),
    [params]
  );
  return { href: `${path}?${searchParams.toString()}` };
}
