import { Title } from 'components';
import { Card, CardContent } from 'components/card';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { MagnifierIllustration } from 'features/shared/illustrations';

interface LawyerEmptyQuestionsProps {
  onClick?: () => void;
}

const LawyerEmptyQuestions = ({
  onClick = () => {}
}: LawyerEmptyQuestionsProps) => {
  const { t } = useTranslation('lawyer');

  return (
    <Card>
      <CardContent className="">
        <div className="flex flex-col max-w-96 items-center pb-10 self-center">
          <MagnifierIllustration />
          <Title variant="h3" component="h2" className="!font-bold mt-2">
            {t('account.lawyer.requests.empty.title')}
          </Title>
          <Paragraph gutterBottom className="mt-2 text-center text-gray-800">
            {t('account.lawyer.requests.empty.description')}
          </Paragraph>
          {onClick && (
            <Button
              className="w-fit"
              size="medium"
              variant="secondary"
              onClick={() => onClick()}
            >
              {t('account.lawyer.requests.empty.clean')}
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default LawyerEmptyQuestions;
