import { CheckCircleIcon, UserIcon } from '@heroicons/react/solid';
import { Divider, Title } from 'components';
import Dot from 'components/Dot';
import { Card, CardContent } from 'components/card';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { LawyerQuestion } from 'services/lawyerQuestion';

import SanitizedContent from 'features/customer/question/SanitizedContent';

import { formatDateToIntl } from 'utils/date';

import QuestionAssignationDisclosure from '../../assignation/components/QuestionAssignationDisclosure';
import AnswerDeadline from './AnswerDeadline';

interface LawyerAssignedQuestionCardProps {
  question: LawyerQuestion;
  isHighPriority?: boolean;
}

const LawyerAssignedQuestionCard = ({
  question,
  isHighPriority = false,
  children
}: PropsWithChildren<LawyerAssignedQuestionCardProps>) => {
  const { t } = useTranslation('lawyer');

  return (
    <Card highlight>
      <CardContent className="mb-1">
        <div className="flex gap-2 items-center">
          <Dot type="important" />
          <Title variant="h3" className="!font-bold">
            {question.title}
          </Title>
        </div>
      </CardContent>
      <CardContent className="!flex-row flex-wrap text-base font-medium gap-5 text-gray-800">
        <div className="flex items-center">
          <UserIcon className="size-4 mr-1.5 " />
          {question.member.full_name}
        </div>
        <div className="flex items-center">
          <CheckCircleIcon className="size-4 mr-1.5" />
          {t('account.lawyer.requests.askedAt', {
            date: formatDateToIntl(new Date(question.created_at), 'long')
          })}
        </div>
        <div className="flex items-center">
          <AnswerDeadline answerDeadLine={question.answer_deadline_at} />
        </div>
      </CardContent>
      <Divider className="!py-2" />
      <QuestionAssignationDisclosure defaultOpen={isHighPriority}>
        <div className="flex flex-col gap-4 prose">
          <SanitizedContent content={question.content} />
          <div className="flex gap-2 justify-end">{children}</div>
        </div>
      </QuestionAssignationDisclosure>
    </Card>
  );
};

export default LawyerAssignedQuestionCard;
