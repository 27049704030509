import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RequestMagicLinkFormInputs } from '../validation';

const LoginPasswordLink = ({
  control
}: {
  control: Control<RequestMagicLinkFormInputs>;
}) => {
  const { t } = useTranslation('customer');
  const email = useWatch({ control, name: 'email' });

  return (
    <Link
      state={{ email }}
      className="border border-black block mt-4 rounded w-full text-black text-center font-medium px-4 py-2 text-base hover:bg-black hover:text-white"
      to="/login?password=true"
    >
      {t('login.switch.password')}
    </Link>
  );
};

export default LoginPasswordLink;
