import { ErrorBoundary } from '@sentry/react';
import GenericErrorCard from 'components/card/GenericErrorCard';

import { PageLayout } from 'features/customer/account/content';
import { LawyerQuestionsAssignation } from 'features/questions';

const LawyerAssignationsPage = () => (
  <PageLayout>
    <ErrorBoundary fallback={<GenericErrorCard />}>
      <LawyerQuestionsAssignation />
    </ErrorBoundary>
  </PageLayout>
);

export default LawyerAssignationsPage;
