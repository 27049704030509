import { Menu } from '@headlessui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { ReactComponent as EkieLogo } from 'assets/ekie.svg';
import { MobileNav } from 'components';
import LinkNav from 'components/LinkNav';
import { Link, useLocation } from 'react-router-dom';

import SubMenu from 'features/menu/components/SubMenu';
import { LawyerQuestionsCount } from 'features/questions';
import {
  ConsultationsIcon,
  DashboardIcon,
  LawyerCareIcon,
  QuestionsIcon
} from 'features/shared/icons';

import { IMenuItem, PageName } from 'types/account';

import LawyerProfileMenu from './LawyerProfileMenu';

const LAWYER_MENU_ITEMS: IMenuItem[] = [
  {
    name: PageName.Dashboard,
    href: '/account/dashboard',
    icon: DashboardIcon
  },
  {
    name: PageName.Calendar,
    href: '/account/calendar',
    icon: DashboardIcon
  },
  {
    name: PageName.Questions,
    icon: QuestionsIcon,
    href: '/account/questions',
    subMenus: [
      {
        name: PageName.QuestionsAssignation,
        href: '/account/questions-assignation',
        extra: <LawyerQuestionsCount status={['assigned']} animate />
      },
      {
        name: PageName.QuestionsToAnswer,
        href: '/account/questions-to-answer',
        extra: <LawyerQuestionsCount status={['assignment_accepted']} />
      }
    ]
  },
  {
    name: PageName.Consultations,
    href: '/account/consultations',
    icon: ConsultationsIcon
  },
  {
    name: PageName.Requests,
    href: '/account/requests',
    icon: DocumentTextIcon
  },
  {
    name: PageName.LawyerCare,
    href: '/account/lawyercare',
    icon: LawyerCareIcon
  }
];

const LAWYER_MENU_MOBILE_ITEMS: IMenuItem[] = [
  {
    name: PageName.Profile,
    href: '/account/profile'
  }
];

const LawyerMenu = () => {
  const { pathname } = useLocation();

  const menuItems = LAWYER_MENU_ITEMS.map((item) => {
    if (item.subMenus) {
      return (
        <SubMenu
          key={item.name}
          translation="lawyer"
          item={item}
          items={item.subMenus}
        />
      );
    }

    return <LinkNav key={item.name} translation="lawyer" item={item} />;
  });

  return (
    <>
      <div className="hidden md:flex md:fixed flex-col items-center justify-between h-full py-4 z-50">
        <div className="w-full md:w-auto md:max-w-lg">
          <div className="md:pb-12 pt-4">
            <Link to="/account/dashboard">
              <EkieLogo className="md:ml-3" />
            </Link>
          </div>
          <div className="flex flex-col justify-between">
            <Menu as="nav" className="flex flex-col w-52">
              {menuItems}
            </Menu>
          </div>
        </div>
        <LawyerProfileMenu />
      </div>
      <MobileNav
        translation="lawyer"
        isActiveMemberCse
        accountTabs={[...LAWYER_MENU_ITEMS, ...LAWYER_MENU_MOBILE_ITEMS].map(
          (item) => ({
            ...item,
            current: pathname.includes(item.name)
          })
        )}
      />
    </>
  );
};

export default LawyerMenu;
