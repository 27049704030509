import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { acceptQuestionAssignation } from 'services/lawyers/assignation';

import { Button } from 'features/shared/buttons';
import { Toast } from 'features/shared/toasts';

import { AcceptAssignationModal } from './components';

interface AcceptQuestionAssignationProps {
  questionId: number;
}

const AcceptQuestionAssignation = ({
  questionId
}: AcceptQuestionAssignationProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('lawyer');
  const [isOpen, setIsOpen] = useState(false);

  const { mutate, isPending: acceptIsLoading } = useMutation({
    mutationFn: () => acceptQuestionAssignation(questionId),
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('account.questions.assignations.error')}
        />
      );
    },
    onSuccess: () => setIsOpen(true)
  });

  const onConfirmClose = () => {
    queryClient.invalidateQueries({
      queryKey: ['lawyer-questions'],
      refetchType: 'all'
    });
    setIsOpen(false);
  };

  return (
    <>
      <Button
        isLoading={acceptIsLoading}
        variant="tertiary"
        size="medium"
        label={t('account.questions.assignations.accept')}
        onClick={mutate}
      />
      <AcceptAssignationModal
        questionId={questionId}
        isModalOpen={isOpen}
        handleModalClose={onConfirmClose}
      />
    </>
  );
};

export default AcceptQuestionAssignation;
