import { XIcon } from '@heroicons/react/outline';
import { useQuery } from '@tanstack/react-query';
import { getLawyerQuestionAnswer } from 'services/answer';
import { getLawyerQuestion } from 'services/lawyerQuestion';

import { LawyerAnswerCard } from 'features/answer/components';

import LawyerQuestionCard from './LawyerQuestionCard';

interface QuestionSidePanelProps {
  questionId?: number | null;
  onClose: (isOpen: boolean) => void;
}

const QuestionSidePanel = ({ questionId, onClose }: QuestionSidePanelProps) => {
  const {
    data: question,
    isPending: isQuestionPending,
    isSuccess
  } = useQuery({
    queryKey: ['lawyer-question', questionId],
    queryFn: () => getLawyerQuestion(Number(questionId)),
    enabled: !!questionId
  });

  const {
    data: answer,
    isPending: isAnswerPending,
    isSuccess: isAnswerSuccess
  } = useQuery({
    queryKey: ['lawyer-answer', questionId],
    queryFn: () => getLawyerQuestionAnswer(Number(questionId)),
    enabled: !!questionId
  });

  return (
    <div className="relative">
      <div
        className={`fixed top-0 right-0 h-full z-[1000] w-full sm:w-5/6 lg:w-1/3 bg-white shadow-lg transform transition-transform ease-in-out duration-300 ${
          questionId ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-4 flex justify-end">
          <button
            type="button"
            onClick={() => onClose(false)}
            className="flex text-gray-600 self-end"
          >
            <XIcon className="size-5" />
          </button>
        </div>
        {(!isQuestionPending || !isAnswerPending) &&
          isSuccess &&
          isAnswerSuccess && (
            <div className="flex flex-col p-4 overflow-y-auto max-h-[80vh] gap-8">
              <LawyerQuestionCard compact question={question} />
              <LawyerAnswerCard answer={answer} />
            </div>
          )}
      </div>
      {questionId && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onKeyPress={() => onClose(false)}
          className="fixed inset-0 bg-black bg-opacity-50"
          onClick={() => onClose(false)}
        />
      )}
    </div>
  );
};

export default QuestionSidePanel;
