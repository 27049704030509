import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface ChipProps {
  color?: string;
  className?: string;
}

const COLOR_MAPS = {
  'purple-outline': 'text-purple-600 border-purple-600 bg-purple-100',
  purple: 'text-white bg-purple-900',
  blue: 'text-blue-800 border-blue-600 bg-blue-100',
  red: 'text-red-800 border-red-600 bg-red-100'
} as { [key: string]: string };

const Chip = ({
  children,
  className,
  color = 'purple-outline'
}: PropsWithChildren<ChipProps>) => (
  <span
    className={classNames(
      className,
      COLOR_MAPS[color],
      ' text-white text-[10px] leading-3 w-fit font-bold p-1 min-w-5 inline-flex items-center justify-center rounded-full'
    )}
  >
    {children}
  </span>
);

export default Chip;
