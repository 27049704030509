import { UserCircleIcon } from '@heroicons/react/solid';
import { Card, Divider, Title } from 'components';
import { CardContent } from 'components/card';
import { LawyerQuestion } from 'services/lawyerQuestion';

import SanitizedContent from 'features/customer/question/SanitizedContent';
import { Paragraph } from 'features/shared/body';

import AnswerDeadline from './AnswerDeadline';

interface LawyerQuestionCardProps {
  question: LawyerQuestion;
  compact?: boolean;
}

const LawyerQuestionCard = ({
  question,
  compact = false
}: LawyerQuestionCardProps) => (
  <Card className="flex-1" compact={compact}>
    <div className="flex gap-2 items-center">
      <UserCircleIcon className="size-8 text-gray-800" />
      <Title variant="h3">{question.member.full_name}</Title>
    </div>
    <Paragraph className="text-gray-700 italic" size="sm">
      {question.title}
    </Paragraph>
    <Divider />
    <CardContent className="items-center">
      <SanitizedContent content={question.content} />
    </CardContent>
    <Divider />
    <div className="self-end">
      {!question.answer_id && (
        <AnswerDeadline
          displayDate
          answerDeadLine={question.answer_deadline_at}
        />
      )}
    </div>
  </Card>
);

export default LawyerQuestionCard;
