import { Fade } from 'components/transition';
import { range } from 'lodash';

const AdministratorListSkeleton = () => (
  <Fade>
    <div className="animate-pulse p-4">
      {range(5).map((key) => (
        <div key={key} className="flex items-center py-2 border-b ">
          <div className="h-6 w-1/2 bg-gray-300 rounded" />
          <div className="h-6 w-1/2 bg-gray-300 rounded ml-4" />
        </div>
      ))}
    </div>
  </Fade>
);

export default AdministratorListSkeleton;
