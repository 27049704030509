import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Chip } from 'components';
import { QuestionStatus, getLawyerQuestions } from 'services/lawyerQuestion';

const PAGE_SIZE = 1;
const CURRENT_PAGE = 1;

interface LawyerQuestionsCountProps {
  status: QuestionStatus[];
  memberId?: number;
  animate?: boolean;
}

const LawyerQuestionsCount = ({
  status,
  memberId,
  animate = false
}: LawyerQuestionsCountProps) => {
  const {
    data: questionsResponse,
    isLoading: isQuestionsLoading,
    isSuccess: isQuestionsSuccess
  } = useQuery({
    queryKey: ['lawyer-questions', { status, memberId }, CURRENT_PAGE],
    queryFn: () =>
      getLawyerQuestions({
        status,
        memberId,
        pageSize: PAGE_SIZE,
        page: CURRENT_PAGE
      }),
    refetchOnWindowFocus: true
  });

  if (isQuestionsLoading) return <div />;
  if (!isQuestionsSuccess) return <div />;
  if (questionsResponse.total === 0) return <div />;

  return (
    <span className="relative flex ml-2">
      <span
        className={classNames(
          'absolute inline-flex z-10 size-5 rounded-full bg-purple-600',
          animate && 'animate-ping-soft'
        )}
      />
      <Chip color="purple" className="z-20">
        {questionsResponse.total}
      </Chip>
    </span>
  );
};

export default LawyerQuestionsCount;
