import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

interface ArrowButtonPaginationProps {
  direction?: 'previous' | 'next';
}

const ArrowButtonPagination = ({
  direction = 'previous'
}: ArrowButtonPaginationProps) => (
  <div
    className={classNames(
      'h-8 w-8 rounded-full bg-white flex justify-center items-center p-1',
      direction === 'previous' ? 'ml-6 rotate-90' : '-rotate-90'
    )}
  >
    <ChevronDownIcon className="size-5" stroke="#404040" />
  </div>
);

export default ArrowButtonPagination;
