import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AuthenticatedMemberInfo,
  SKIP_ONBOARDING_COMPLETION,
  getAuthenticatedMemberInfo
} from 'services/member';
import { useReadLocalStorage } from 'usehooks-ts';

const computeRoute = (
  hasSkipOnboarding: boolean | null
): Record<AuthenticatedMemberInfo['onboarding_status'], string | null> => ({
  completed: null,
  profile_data_missing: '/onboarding/profile',
  not_started: hasSkipOnboarding ? null : '/onboarding/situation'
});

const useMemberOnboardingChecker = () => {
  const navigate = useNavigate();
  const hasSkipOnboarding: boolean | null = useReadLocalStorage(
    SKIP_ONBOARDING_COMPLETION
  );

  const { data: member, isSuccess } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  useEffect(() => {
    if (isSuccess) {
      const { onboarding_status: onboardingStatus } = member;
      const routes = computeRoute(hasSkipOnboarding);
      const route = routes[onboardingStatus];

      if (route) navigate(route);
    }
  }, [isSuccess, member, navigate, hasSkipOnboarding]);
};

export default useMemberOnboardingChecker;
