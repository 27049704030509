import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import Back from 'components/Back';
import FormHelper from 'components/FormHelper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getLawyerQuestionAnswer } from 'services/answer';
import { getLawyerQuestion } from 'services/lawyerQuestion';

import { SaveAnswer } from 'features/answer';
import {
  LawyerMemberQuestionHistory,
  LawyerMemberSimilarQuestions,
  LawyerQuestionsCount
} from 'features/questions';
import { QUESTIONS_STATUSES } from 'features/questions/LawyerQuestionHistory';
import LawyerSimilarQuestionsCount from 'features/questions/LawyerSimilarQuestionsCount';
import { LawyerQuestionCard } from 'features/questions/components';
import QuestionSidePanel from 'features/questions/components/QuestionSidePanel';
import { LayoutLoadingSkeleton } from 'features/shared/layout';

import { useLawyer } from '../../hooks';

export default function ToAnswerPage() {
  const { questionId = '' } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('lawyer');
  const [selectQuestionId, seSelectedQuestionId] = useState<null | number>(
    null
  );
  const { can_access_ai_suggestion_answer: canAccessAiSuggestionAnswer } =
    useLawyer();

  const { data: question, isPending: isQuestionPending } = useQuery({
    queryKey: ['lawyer-question', questionId],
    queryFn: () => getLawyerQuestion(Number(questionId)),
    refetchOnWindowFocus: true,
    enabled: !!questionId
  });

  const isAnswerQueryEnabled = !!question && question.answer_id !== null;

  const { data: answer, isPending: isAnswerPending } = useQuery({
    queryKey: ['lawyer-answer', question?.answer_id],
    queryFn: () => getLawyerQuestionAnswer(Number(questionId)),
    gcTime: 0,
    enabled: isAnswerQueryEnabled
  });

  const onClosePanel = () => {
    seSelectedQuestionId(null);
    navigate(`/account/questions/questions-to-answer/${questionId}`);
  };

  if (!questionId) return <div />;
  if (isQuestionPending || (isAnswerQueryEnabled && isAnswerPending))
    return <LayoutLoadingSkeleton />;

  return (
    <div>
      <Back label={t('account.lawyer.requests.toAnswer.back')} />
      {question && (
        <div className="flex flex-col lg:flex-row mx-4 gap-4">
          <div className="container w-full lg:w-2/3 flex flex-col gap-4">
            <LawyerQuestionCard question={question} />
            <div>
              {canAccessAiSuggestionAnswer && question.ai_suggestion ? (
                <FormHelper
                  title={t('account.lawyer.question.answer.helper.title')}
                >
                  {question.ai_suggestion}
                </FormHelper>
              ) : (
                <FormHelper
                  title={t(
                    'account.lawyer.question.answer.advices.howTo.title'
                  )}
                  items={[
                    t('account.lawyer.question.answer.advices.howTo.advice1'),
                    t('account.lawyer.question.answer.advices.howTo.advice2'),
                    t('account.lawyer.question.answer.advices.howTo.advice3'),
                    t('account.lawyer.question.answer.advices.howTo.advice4')
                  ]}
                />
              )}
              <SaveAnswer question={question} draftAnswer={answer} />
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            <TabGroup className="">
              <TabList className="flex border-b border-gray-300">
                <Tab
                  key="member"
                  className="flex items-center px-4 py-2 text-sm font-semibold rounded-t-lg transition-all duration-200 border-b-2 border-transparent focus:outline-none data-[selected]:border-purple-800 data-[selected]:text-purple-800 data-[selected]:bg-white hover:text-purple-600 data-[selected]:shadow-md data-[selected]:translate-y-[-2px]"
                >
                  {t('account.lawyer.questions.tabs.member.title')}
                  <LawyerQuestionsCount
                    status={QUESTIONS_STATUSES}
                    memberId={question.member.id}
                  />
                </Tab>
                <Tab
                  key="history"
                  className="flex items-center px-4 py-2 text-sm font-semibold rounded-t-lg transition-all duration-200 border-b-2 border-transparent focus:outline-none data-[selected]:border-purple-800 data-[selected]:text-purple-800 data-[selected]:bg-white hover:text-purple-600 data-[selected]:shadow-md data-[selected]:translate-y-[-2px]"
                >
                  {t('account.lawyer.questions.tabs.similar.title')}
                  <LawyerSimilarQuestionsCount
                    questionId={Number(questionId)}
                  />
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel key="member" className="p-4 bg-white -mt-1 shadow-md">
                  <LawyerMemberQuestionHistory
                    onQuestionClick={(id) => seSelectedQuestionId(id)}
                    member={question.member}
                  />
                </TabPanel>
                <TabPanel key="member" className="p-4 bg-white -mt-1 shadow-md">
                  <LawyerMemberSimilarQuestions
                    onQuestionClick={(id) => seSelectedQuestionId(id)}
                    questionId={question.id}
                  />
                </TabPanel>
              </TabPanels>
            </TabGroup>
            <div className="relative">
              <QuestionSidePanel
                questionId={selectQuestionId}
                onClose={onClosePanel}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
