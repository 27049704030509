import { Card, Divider } from 'components';
import { Fade } from 'components/transition';
import { range } from 'lodash';

interface LawyerRequestsListSkeletonProps {
  number?: number;
}

const LawyerRequestsListSkeleton = ({
  number = 8
}: LawyerRequestsListSkeletonProps) => (
  <Fade>
    <div className="flex flex-col gap-6 my-6">
      {range(1, number).map((value) => (
        <Card key={value} className="animate-pulse flex flex-row">
          <div className="h-12 w-2/3 bg-gray-300 rounded" />
          <Divider />
          <div className="h-4 w-1/3 mb-3 bg-gray-300 rounded" />
        </Card>
      ))}
    </div>
  </Fade>
);

export default LawyerRequestsListSkeleton;
