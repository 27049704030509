import { ErrorBoundary } from '@sentry/react';
import GenericErrorCard from 'components/card/GenericErrorCard';
import { isEmpty } from 'lodash';

import {
  LawyerQuestionHistory,
  LawyerQuestionsFilters
} from 'features/questions';

const RequestsPage = () => (
  <ErrorBoundary fallback={<GenericErrorCard />}>
    <LawyerQuestionsFilters>
      {({ cleanFilters, onChangePage, ...filters }: any) => (
        <LawyerQuestionHistory
          onCleanFilters={cleanFilters}
          onChangePage={(page) => onChangePage(String(page))}
          filters={{
            ...filters,
            memberId: isEmpty(filters.member) ? undefined : filters.member.id,
            tagId: isEmpty(filters.tag) ? undefined : filters.tag.id,
            status: isEmpty(filters.statuses) ? undefined : filters.statuses,
            content: isEmpty(filters.content) ? undefined : filters.content
          }}
        />
      )}
    </LawyerQuestionsFilters>
  </ErrorBoundary>
);

export default RequestsPage;
