import { useQuery } from '@tanstack/react-query';
import ArrowButtonPagination from 'components/pagination/ArrowButtonPagination';
import BreakPagePagination from 'components/pagination/BreakPagePagination';
import { LawyerRequestsListSkeleton } from 'components/skeleton';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import {
  LawyerQuestion,
  QuestionStatus,
  getLawyerQuestions
} from 'services/lawyerQuestion';

import { LawyerEmptyQuestions, LawyerQuestionOverview } from './components';

interface LawyerQuestionHistoryProps {
  onCleanFilters?: () => void;
  onChangePage: (page: number) => void;
  filters: {
    content?: string;
    memberId?: number;
    page: number;
  };
}

const PAGE_SIZE = 10;
export const QUESTIONS_STATUSES: QuestionStatus[] = [
  'answer_validated',
  'answered',
  'additional_request_answered',
  'reviewed',
  'archived'
];

const LawyerQuestionHistory = ({
  filters,
  onChangePage,
  onCleanFilters
}: LawyerQuestionHistoryProps) => {
  const {
    data: questionsResponse,
    isLoading: isQuestionsLoading,
    isSuccess: isQuestionsSuccess
  } = useQuery({
    queryKey: ['lawyer-questions', { status: QUESTIONS_STATUSES, ...filters }],
    queryFn: () =>
      getLawyerQuestions({
        ...filters,
        page: filters.page,
        status: QUESTIONS_STATUSES,
        pageSize: PAGE_SIZE,
        orderBy: '-created_at'
      }),
    refetchOnWindowFocus: true,
    placeholderData: (prev) => prev
  });

  if (isQuestionsLoading) return <LawyerRequestsListSkeleton />;
  if (!isQuestionsSuccess)
    return <LawyerEmptyQuestions onClick={onCleanFilters} />;

  const isEmpty =
    questionsResponse.total === 0 || questionsResponse.items.length === 0;

  return (
    <div className="flex my-8 mx-4 flex-col gap-4 max-w-[1080px]">
      {isEmpty && <LawyerEmptyQuestions onClick={onCleanFilters} />}
      {questionsResponse.items.map((question) => (
        <Link
          key={question.id}
          to={`/account/questions/questions-answered/${question.id}`}
        >
          <LawyerQuestionOverview question={question as LawyerQuestion} />
        </Link>
      ))}
      {!isEmpty && (
        <ReactPaginate
          onPageChange={(page) => onChangePage(page.selected + 1)}
          initialPage={filters.page - 1}
          breakLabel={<BreakPagePagination />}
          nextLabel={<ArrowButtonPagination direction="next" />}
          containerClassName="flex justify-center items-center last:mr-0 first:ml-0 transition-all max-w-full gap-2"
          pageClassName="flex justify-center items-center px-1.5 cursor-pointer font-semibold"
          activeClassName="rounded-md text-white bg-purple-800 font-semibold"
          pageLinkClassName="p-1.5"
          pageRangeDisplayed={2}
          pageCount={questionsResponse.total / PAGE_SIZE}
          previousLabel={<ArrowButtonPagination />}
        />
      )}
    </div>
  );
};

export default LawyerQuestionHistory;
