import classNames from 'classnames';
import { format } from 'date-fns';

import { useLocale } from 'hooks';

interface ConsultationDayProps {
  date: Date;
  border?: 'left' | 'right';
}

const ConsultationDay = ({ date, border = 'right' }: ConsultationDayProps) => {
  const { dateLocale } = useLocale();

  return (
    <div
      className={classNames(
        'flex flex-col gap-px w-32 justify-center text-center font-bold border-gray-400',
        border === 'right' ? 'pr-4 border-r' : 'pl-4 border-l'
      )}
    >
      <div className="text-base capitalize text-gray-800">
        {format(date, 'EEEE', {
          locale: dateLocale
        })}
      </div>
      <div className="text-3xl">
        {format(date, 'dd', {
          locale: dateLocale
        })}
      </div>
      <div className="text-sm capitalize text-gray-800">
        {format(date, 'LLLL', {
          locale: dateLocale
        })}
      </div>
    </div>
  );
};

export default ConsultationDay;
