import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bulkDeleteMembers } from 'services/admin/members';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';
import { LayoutModal } from 'features/shared/layout';

interface CustomerMemberBulkDeleteProps {
  memberIds: number[];
  onSuccess?: Function;
}

const CustomerMemberBulkDelete = ({
  memberIds,
  onSuccess = () => {}
}: CustomerMemberBulkDeleteProps) => {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isPending, isSuccess, isIdle, reset } = useMutation({
    mutationFn: () => bulkDeleteMembers(memberIds),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['customer-members'] })
  });

  const closeModale = () => {
    setIsModalOpen(false);
    reset();
    isSuccess && onSuccess();
  };

  return (
    <div>
      <Button
        className="!text-red-800 !p-0 !font-bold"
        variant="transparent"
        size="medium"
        onClick={() => setIsModalOpen(true)}
      >
        {t('admin.collaborators.table.bulkActions.archive')}
      </Button>
      <LayoutModal isModalOpen={isModalOpen} handleModalClose={closeModale}>
        <Title variant="h3" text="Suppression d'un collaborateur" />
        {isSuccess && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.form.bulkDelete.success')}
            </Paragraph>
            <div className="flex justify-end">
              <Button
                variant="tertiary"
                size="medium"
                label={t('general.close')}
                onClick={closeModale}
              />
            </div>
          </div>
        )}
        {(isIdle || isPending) && (
          <div className="flex flex-col gap-5 my-5">
            <Paragraph>
              {t('admin.collaborators.modal.bulkDelete.text', {
                count: memberIds.length
              })}
            </Paragraph>
            <div className="flex gap-4 justify-end">
              <Button
                onClick={closeModale}
                variant="secondary"
                size="medium"
                label={t('general.cancel')}
              />
              <Button
                onClick={() => mutate()}
                isLoading={isPending}
                variant="custom"
                size="medium"
                label={t('admin.collaborators.modal.delete.submit')}
                customClassNames="bg-purple-900 hover:bg-purple-900 text-white justify-center font-medium w-fit"
              />
            </div>
          </div>
        )}
      </LayoutModal>
    </div>
  );
};

export default CustomerMemberBulkDelete;
