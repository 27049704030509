import { Outlet } from 'react-router-dom';

import AdminNav from 'features/customer/account/nav/AdminNav';

export default function AdminPage() {
  return (
    <div className="min-h-screen bg-gray-200 md:flex">
      <div
        id="sidebar"
        className="md:my-4 md:ml-2 rounded-2xl bg-white md:w-[256px] md:min-w-[256px]"
      >
        <AdminNav />
      </div>
      <div
        id="content"
        className="mt-[72px] md:mt-0 md:col-span-2 bg-gray-200 z-10 pt-4 md:pt-4 pb-6 w-full"
      >
        <Outlet />
      </div>
    </div>
  );
}
