import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAuthenticatedMemberInfo } from 'services/member';

import { Alert } from 'features/shared/alerts';

const LaborLawAskQuestionWarning = (): ReactElement | null => {
  const { t } = useTranslation('customer');
  const { data: member, isSuccess: isMemberSuccess } = useQuery({
    queryKey: ['authenticated-member-info'],
    queryFn: () => getAuthenticatedMemberInfo()
  });

  if (!isMemberSuccess) return null;

  if (member?.has_labor_law_access) return null;

  return (
    <Alert type="info" className="mb-4">
      {t('account.subscription.banner.info')}
    </Alert>
  );
};

export default LaborLawAskQuestionWarning;
