import { Moment } from 'moment';

import { privateAxios } from 'context/auth/auth.utils';

import { apiPrivatePythonClient } from '../axios';

export type AddBulkPartnerCollaboratorInput = {
  step: number;
  file: any;
  withOnboardingEmail: boolean;
  sendOnboardingEmailDate: Moment | undefined;
};

export type AddBulkCollaboratorsResponse = {
  addedCollaboratorResult: {
    total: number;
    succeeded: {
      total: number;
    };
    failed: {
      total: number;
      errors?: {
        email: string;
        code: string;
      }[];
    };
  };
  archivedCollaboratorResult: {
    total: number;
    succeeded: {
      total: number;
    };
    failed: {
      total: number;
      errors?: {
        email: string;
        code: string;
      }[];
    };
  };
};

export type Collaborator = {
  id: string;
  customerId: number;
  principalEmail: string;
  firstName: string;
  lastName: string;
  secondaryEmail: null;
  productName: null;
  createdAt: string;
  archivedOn: string;
  type: 'cse' | 'employee';
};

export type Pagination = {
  nbItemsOnCurrentPage: number;
  currentPage: number;
  nextPage: number | null;
  previousPage: number | null;
  nbItemsPerPage: number;
  totalItems: number;
  totalPages: number;
};

export type CollaboratorsByPartnerIdResponse = {
  collaborators: Collaborator[];
  pagination: Pagination;
};

export async function getCollaboratorsByPartnerId(
  partnerId: string,
  search: string | null,
  archived = false,
  page = 1
): Promise<CollaboratorsByPartnerIdResponse> {
  const res = await privateAxios.get(
    `/partners/${partnerId}/collaborators?&page=${page}${
      search ? `&string=${search}` : ''
    }${archived ? `&archived=${archived}` : ''}`
  );

  return res.data;
}

export async function addBulkCollaborators(
  partnerId: string,
  data: AddBulkPartnerCollaboratorInput
): Promise<AddBulkCollaboratorsResponse> {
  const formData = new FormData();
  formData.append('collaborators', data.file.file);
  formData.append('withOnboardingEmail', String(data.withOnboardingEmail));
  if (data.sendOnboardingEmailDate) {
    formData.append(
      'sendOnboardingEmailDate',
      data.sendOnboardingEmailDate.toISOString(true)
    );
  }

  const res = await privateAxios.post(
    `/partners/${partnerId}/collaborators/batch`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

  return res.data;
}

export async function bulkDeleteMembers(memberIds: number[]) {
  await Promise.all(memberIds.map((memberId) => deleteMember(memberId)));
}

export async function deleteMember(memberId: number): Promise<void> {
  await apiPrivatePythonClient.delete(`/member/members/${memberId}`);
}

export type CreateMemberPayload = {
  email: string;
  firstName: string;
  lastName: string;
  type: 'cse' | 'employee';
};

export async function createMember(
  payload: CreateMemberPayload
): Promise<void> {
  await apiPrivatePythonClient.post(`/member/members`, {
    email: payload.email,
    first_name: payload.firstName,
    last_name: payload.lastName,
    is_cse_member: payload.type === 'cse'
  });
}

export type UpdateMemberPayload = {
  memberId: number;
  type: 'cse' | 'employee';
};

export async function updateMember(
  payload: UpdateMemberPayload
): Promise<void> {
  await apiPrivatePythonClient.put(`/member/members/${payload.memberId}`, {
    is_cse_member: payload.type === 'cse'
  });
}

export async function sendOnboardingEmail(memberId: number): Promise<void> {
  await apiPrivatePythonClient.post(
    `/member/members/${memberId}/send-onboarding-email`
  );
}

export async function bulkSendOnboardingEmail(
  memberIds: number[]
): Promise<void> {
  await Promise.all(memberIds.map((memberId) => sendOnboardingEmail(memberId)));
}

export type Member = {
  id: number;
  email: string;
  collaborator_id: number;
  full_name: string;
  is_cse_member: boolean;
  added_at: string;
};

export type MemberResponse = {
  total: number;
  items: Member[];
};
