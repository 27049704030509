import classNames from 'classnames';

interface DotProps {
  icon?: React.ElementType;
  type?: 'info' | 'action' | 'important' | 'error';
  pulse?: boolean;
  className?: string;
}

const Dot = ({
  icon,
  type = 'important',
  pulse = false,
  className
}: DotProps) => {
  const Icon = icon;

  return (
    <span className="relative flex">
      {pulse && (
        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-purple-400 opacity-75" />
      )}
      <span
        className={classNames(
          'relative flex items-center justify-center rounded-full',
          icon ? 'w-6 h-6' : 'w-3.5 h-3.5',
          {
            'bg-purple-800': type === 'important',
            'bg-purple-300': type === 'info',
            'border-purple-800 border-[3px] bg-white': type === 'action',
            'bg-red-800': type === 'error'
          },
          className
        )}
      >
        {Icon && <Icon className="h-4 w-4 text-white" aria-hidden="true" />}
      </span>
    </span>
  );
};

export default Dot;
