import { TabPanel, TabPanels } from '@headlessui/react';
import { Section, Tabs } from 'components';
import { useTranslation } from 'react-i18next';

import { CollaboratorsList } from 'features/admin/members';
import { PageLayout } from 'features/customer/account/content';
import { Paragraph } from 'features/shared/body';

const TABS_MAP = [
  { name: 'admin.collaborators.tabs.actives', id: 'actives' },
  { name: 'admin.collaborators.tabs.archived', id: 'archived' }
];

const CollaboratorsPage = () => {
  const { t } = useTranslation('customer');

  return (
    <PageLayout>
      <Section title={t('admin.collaborators.title')}>
        <Paragraph
          className="my-6 text-gray-550 font-normal"
          text={t('admin.collaborators.section.upload.title')}
        />
        <Tabs
          items={TABS_MAP.map((item) => ({
            ...item,
            name: t(item.name as any)
          }))}
        >
          <TabPanels>
            <TabPanel className="relative">
              <CollaboratorsList />
            </TabPanel>
            <TabPanel>
              <CollaboratorsList archived />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Section>
    </PageLayout>
  );
};

export default CollaboratorsPage;
