import { useQuery } from '@tanstack/react-query';
import { Chip, Empty, Title } from 'components';
import { Pagination } from 'components/pagination';
import { QuestionsListSkeleton } from 'components/skeleton';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  DEFAULT_QUESTIONS_PAGE_SIZE,
  QuestionSource,
  getMemberQuestions
} from 'services/question';

import MemberQuestionOverview from './components/MemberQuestionOverview';

interface MemberQuestionsListProps {
  defaultPage: number;
  source?: QuestionSource;
}

const MemberQuestionsList = ({
  defaultPage = 1,
  source = 'personal'
}: MemberQuestionsListProps) => {
  const [page, setPage] = useState(defaultPage);

  const { data, isLoading, isSuccess, refetch } = useQuery({
    queryKey: ['member-questions-list', source, page],
    queryFn: () => getMemberQuestions({ source, page })
  });

  const { t } = useTranslation('customer');

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  const onRefetch = (newPage: number) => setPage(newPage);

  return (
    <div>
      {isLoading && <QuestionsListSkeleton />}
      {isSuccess && data.items.length ? (
        <>
          <Title gutterBottom variant="h2">
            {t('account.requests.title')}
            <Chip color="purple" className="text-base py-1.5 ml-2">
              {data.total}
            </Chip>
          </Title>
          <div className="grid grid-cols-1 gap-10">
            {data.items.map((question) => (
              <NavLink
                to={`/questions/${question.id}`}
                key={question.id}
                state={{ previousPage: page, type: source }}
              >
                <MemberQuestionOverview source={source} question={question} />
              </NavLink>
            ))}
            <Pagination
              initialPage={defaultPage}
              onRefetch={onRefetch}
              pageCount={data.total / DEFAULT_QUESTIONS_PAGE_SIZE}
            />
          </div>
        </>
      ) : (
        <Empty
          to="/account/ask"
          imgSrc="/images/empty-questions.webp"
          imgAlt={t('account.questions.empty.alt')}
          linkLabel={t('account.questions.empty.cta')}
        />
      )}
    </div>
  );
};

export default MemberQuestionsList;
