import {
  ComboboxButton,
  ComboboxInput,
  ComboboxInputProps
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export type ComboboxAutocompleteInputProps<T> = {
  displayValue: (item: T | null) => string;
} & Omit<ComboboxInputProps, 'displayValue'>;

const ComboboxAutocompleteInput = <T,>({
  children,
  displayValue,
  ...props
}: ComboboxAutocompleteInputProps<T>) => (
  <div className="relative">
    <ComboboxInput
      className={classNames(
        'w-52 rounded-md py-2 pr-8 pl-3 text-sm',
        'focus:outline-none focus:border-purple-600 focus:ring-purple-600 placeholder:text-gray-700'
      )}
      displayValue={(item) => displayValue(item as unknown as T | null)}
      {...props}
    />
    {children}
    <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
      <ChevronDownIcon className="size-4 fill-white/60 group-data-[hover]:fill-white" />
    </ComboboxButton>
  </div>
);

export default ComboboxAutocompleteInput;
