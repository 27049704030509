import { TFunctionTranslationFormValidation } from 'schemas';
import * as yup from 'yup';
import 'yup-phone';

export const firstNameSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .string()
    .max(100, t('schema.firstName.max'))
    .required(t('schema.firstName.required'));

export const lastNameSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .string()
    .max(100, t('schema.lastName.max'))
    .required(t('schema.lastName.required'));

export const phoneCellphoneSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .string()
    .phone('FR', false, t('schema.phone.valid'))
    .required(t('schema.phone.required'));

export const emailSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .string()
    .email(t('schema.email.valid'))
    .max(255, t('schema.email.max'))
    .required(t('schema.email.required'));

export const passwordSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .string()
    .required(t('schema.password.required'))
    .min(8, t('schema.password.min'))
    .max(128, t('schema.password.max'))
    .matches(/[A-Z]/, t('schema.password.uppercase'))
    .matches(/[a-z]/, t('schema.password.lowercase'))
    .matches(/\d/, t('schema.password.number'))
    .matches(/[!@#$%^&*(),.?":{}|<>]/, t('schema.password.special'));

export const answerSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .string()
    .min(200, '200 caractères minimum')
    .max(15000, '15000 caractères maximum.');

export const slotSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      id: yup.string(),
      // ISO
      startAt: yup.date(),
      // HH:MM
      dayTime: yup.string()
    })
    .required(t('schema.consultation.slot.required'));

export const questionSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .string()
    .min(20, t('schema.question.min'))
    .required(t('schema.question.required'));

export const cguConsentSchema = (t: TFunctionTranslationFormValidation) =>
  yup.boolean().oneOf([true], t('schema.cguConsent.required'));
