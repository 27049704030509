import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Title } from 'components';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  QuestionRejectInput,
  rejectQuestionAssignation
} from 'services/lawyers/assignation';

import {
  REASON_OPTIONS,
  RejectFormInput
} from 'features/assignation/QuestionRejectForm';
import { assignationRejectSchema } from 'features/assignation/validation';
import { Button } from 'features/shared/buttons';
import { SelectInputGroup } from 'features/shared/forms';
import { LayoutModal } from 'features/shared/layout';
import { Toast } from 'features/shared/toasts';

interface RejectQuestionAssignationProps {
  questionId: number;
}

const RejectQuestionAssignation = ({
  questionId
}: RejectQuestionAssignationProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('lawyer');
  const [isOpen, setIsOpen] = useState(false);
  const { t: tForm } = useTranslation('form');

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm<RejectFormInput>({
    resolver: yupResolver(assignationRejectSchema(tForm)),
    defaultValues: {
      reason: REASON_OPTIONS[0].value
    }
  });
  const reasonWatch = watch('reason');

  const { mutate: rejectQuestionMutate, isPending } = useMutation({
    mutationFn: (data: QuestionRejectInput) => rejectQuestionAssignation(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lawyer-questions'],
        refetchType: 'all'
      });
      setIsOpen(false);
    },
    onError: () => {
      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('account.questions.assignations.error')}
        />
      );
    }
  });

  const onSubmit = (data: RejectFormInput) =>
    rejectQuestionMutate({
      question_id: questionId,
      reason: data.reason,
      message: data.reason === 'other' ? data.message : undefined
    });

  return (
    <>
      <Button
        variant="outline"
        size="medium"
        label={t('account.questions.assignations.reject')}
        onClick={() => setIsOpen(true)}
      />

      <LayoutModal
        handleModalClose={() => setIsOpen(false)}
        isModalOpen={isOpen}
      >
        <Title
          variant="h3"
          text={t('account.lawyer.requests.assignation.reject.title')}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-col gap-4 mt-8"
        >
          <SelectInputGroup
            options={REASON_OPTIONS.map((option) => ({
              ...option,
              label: tForm(option.label as any)
            }))}
            name="reason"
            label={tForm('label.reason')}
            error={errors.reason}
            register={register}
          />
          {reasonWatch === 'other' && (
            <>
              <label className="input-label">{tForm('label.message')}</label>
              <textarea
                rows={3}
                placeholder={tForm('placeholder.message')}
                {...register('message')}
              />
              {errors.message && (
                <p className="mt-2 text-red-800 mb-4">
                  {errors.message.message}
                </p>
              )}
            </>
          )}
          <div className="flex gap-6">
            <Button
              isLoading={isPending}
              fullWidth
              submit
              size="medium"
              variant="primary"
              label={t('account.questions.assignations.reject')}
            />
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default RejectQuestionAssignation;
