import { Combobox, ComboboxOptions } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import ComboboxAutocompleteInput from 'components/ComboboxAutocompleteInput';
import ComboboxAutocompleteOption from 'components/ComboboxAutocompleteOption';
import InputClearButton from 'components/InputClearButton';
import { useTranslation } from 'react-i18next';
import { LawyerMember, getLawyerMembers } from 'services/lawyerMember';
import { useDebounceValue } from 'usehooks-ts';

export type LawyerMemberAutocompleteProps = {
  value?: LawyerMember | null;
  onChange: (value: LawyerMember | null) => void;
};

const LawyerMemberAutocomplete = ({
  value,
  onChange
}: LawyerMemberAutocompleteProps) => {
  const [queryMember, setQueryMember] = useDebounceValue('', 500);
  const { t } = useTranslation('lawyer');

  const { data } = useQuery({
    queryKey: ['lawyer-members', queryMember],
    queryFn: () => getLawyerMembers({ search: queryMember }),
    enabled: !!queryMember,
    placeholderData: (prev) => prev
  });

  const onClear = () => {
    setQueryMember('');
    onChange(null);
  };

  return (
    <Combobox as="div" by="id" value={value} onChange={onChange}>
      <ComboboxAutocompleteInput<LawyerMember>
        placeholder={t('account.lawyer.requests.filters.member.placeholder')}
        displayValue={(member) => member?.full_name || ''}
        onChange={(event) => setQueryMember(event.target.value)}
      >
        {(value || queryMember) && <InputClearButton onClear={onClear} />}
      </ComboboxAutocompleteInput>

      <ComboboxOptions
        anchor="bottom"
        transition
        className={classNames(
          'w-52 bg-white border border-gray-300 mt-1 px-1 py-2 rounded-md shadow-md [--anchor-gap:var(--spacing-1)] empty:invisible',
          'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
        )}
      >
        {data?.items.map((member) => (
          <ComboboxAutocompleteOption key={member.id} value={member}>
            {member.full_name}
          </ComboboxAutocompleteOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  );
};

export default LawyerMemberAutocomplete;
