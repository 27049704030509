import { Control, useWatch } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LoginPasswordFormInputs } from '../validation';

const LoginMagicLinkLink = ({
  control
}: {
  control: Control<LoginPasswordFormInputs>;
}) => {
  const email = useWatch({ control, name: 'email' });

  return (
    <p className="font-medium text-gray-800 text-center">
      <Trans
        ns="customer"
        i18nKey="login.switch.magic"
        components={{
          1: <Link state={{ email }} className="text-purple-900" to="/login" />
        }}
      />
    </p>
  );
};

export default LoginMagicLinkLink;
