import { Title } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'features/shared/buttons';
import { TimerIcon } from 'features/shared/icons';
import { LayoutModal } from 'features/shared/layout';
import { LayoutModalProps } from 'features/shared/layout/LayoutModal';

interface ModalQuestionAcceptedProps
  extends Omit<LayoutModalProps, 'children'> {
  questionId: number;
}

const AcceptAssignationModal = ({
  questionId,
  isModalOpen,
  handleModalClose
}: ModalQuestionAcceptedProps) => {
  const { t } = useTranslation('lawyer');
  const navigate = useNavigate();

  const handleAnswerNow = () => {
    handleModalClose();
    navigate(`/account/questions/questions-to-answer/${questionId}`);
  };

  return (
    <LayoutModal
      handleModalClose={handleModalClose}
      isModalOpen={isModalOpen}
      persisting
      isClosable={false}
    >
      <div>
        <div className="flex flex-col items-center">
          <TimerIcon />
          <Title variant="h3">
            {t('account.questions.inCharge.confirmation.title')}
          </Title>
        </div>
        <div className="text-center">
          <p className="text-md mt-2 font-medium ">
            {t('account.questions.inCharge.confirmation.p')}
          </p>
          <div className="mt-4">
            <div className="flex gap-4 justify-center">
              <Button
                variant="secondary"
                size="medium"
                fullWidth
                label={t('account.questions.inCharge.cta.answerLater')}
                onClick={handleModalClose}
              />
              <Button
                variant="tertiary"
                size="medium"
                fullWidth
                label={t('account.questions.inCharge.cta.answerNow')}
                onClick={handleAnswerNow}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutModal>
  );
};

export default AcceptAssignationModal;
