import { MenuItem, MenuItems } from '@headlessui/react';
import LinkNav from 'components/LinkNav';
import { useTranslation } from 'react-i18next';

import { IMenuItem } from 'types/account';

interface SubMenuProps {
  item: IMenuItem;
  items: IMenuItem[];
  onClickItem?: () => void;
  translation?: 'customer' | 'lawyer';
}

const SubMenu = ({
  item,
  items,
  translation = 'customer',
  onClickItem
}: SubMenuProps) => {
  const { t } = useTranslation(translation);
  const Icon = item.icon;

  return (
    <MenuItems static>
      <MenuItem as="div" className="flex p-3 text-base items-center">
        {Icon && <Icon strokeWidth="1.5" className="w-5" />}
        <span className="ml-3 flex gap-2 items-center font-medium">
          {t(`account.sidebar.${item.name}` as any)}
        </span>
      </MenuItem>
      <div className="border-l-2 border-gray-300 ml-5">
        {items.map((subItem) => (
          <LinkNav
            isSubMenu
            translation={translation}
            item={subItem}
            key={subItem.name}
            onClick={onClickItem}
          />
        ))}
      </div>
    </MenuItems>
  );
};

export default SubMenu;
