import { useTranslation } from 'react-i18next';
import { AddBulkCollaboratorsResponse } from 'services/admin/members';

interface PartnerCollaboratorBulkResumeProps {
  title: string;
  result:
    | AddBulkCollaboratorsResponse['addedCollaboratorResult']
    | AddBulkCollaboratorsResponse['archivedCollaboratorResult'];
}

const PartnerCollaboratorBulkReport = ({
  result,
  title
}: PartnerCollaboratorBulkResumeProps) => {
  const { t } = useTranslation('customer');

  return (
    <div className="my-2">
      <div className="flex items-center">
        <h4 className="font-medium text-sm mr-auto text-gray-700 flex items-center">
          {title}
        </h4>
        <span className="px-2 py-1 rounded-lg bg-red-50 text-red-500 text-xs font-bold">
          {`${result.succeeded.total} / ${result.total}`}
        </span>
      </div>
      {result.failed.errors && (
        <table className="w-full table-auto text-sm">
          <thead>
            <tr>
              <th className="text-left text-gray-800 font-normal py-3 px-4">
                {t('admin.collaborators.table.header.email')}
              </th>
              <th className="text-left text-gray-800 font-normal py-3 px-4">
                {t('admin.collaborators.form.bulk.success.table.header.reason')}
              </th>
            </tr>
          </thead>
          <tbody>
            {result.failed.errors.map((row) => (
              <tr
                key={row.email}
                className="font-medium rounded-md hover:bg-gray-200 border-y-2 border-gray-300"
              >
                <td className="py-3 px-4">{row.email}</td>
                <td className="py-3 px-4">
                  {t(
                    `admin.collaborators.form.bulk.error.table.reason.${row.code}` as any
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PartnerCollaboratorBulkReport;
