import { ConsultationStatus } from 'types/consultation';

import { apiPrivatePythonClient } from './axios';
import { ConsultationDocument } from './document';

export const DEFAULT_CONSULTATIONS_PAGE_SIZE = 5;

export type LawyerConsultation = {
  id: number;
  question_id: number | null;
  start_at: string;
  status: ConsultationStatus;
  document: ConsultationDocument | null;
  note: number | null;
  can_cancel: boolean;
  can_complete: boolean;
  member_full_name: string;
  member_phone_cellphone: string | null;
};

export type LawyerConsultationsPayload = {
  status: ConsultationStatus[];
  page?: number;
  pageSize?: number;
  completable?: boolean;
  orderBy?: '+start_at' | '-start_at';
};

export type LawyerConsultationsResponse = {
  total: number;
  items: LawyerConsultation[];
};

export async function getLawyerConsultations({
  status,
  page = 1,
  completable,
  pageSize = DEFAULT_CONSULTATIONS_PAGE_SIZE,
  orderBy = '+start_at'
}: LawyerConsultationsPayload): Promise<LawyerConsultationsResponse> {
  const { data } =
    await apiPrivatePythonClient.get<LawyerConsultationsResponse>(
      `/lawyer/consultations`,
      {
        params: {
          status,
          page,
          completable,
          page_size: pageSize,
          order_by: orderBy
        }
      }
    );

  return data;
}

export async function getLawyerConsultation(
  consultationId: LawyerConsultation['id']
): Promise<LawyerConsultation> {
  const { data } = await apiPrivatePythonClient.get<LawyerConsultation>(
    `/lawyer/consultations/${consultationId}`
  );

  return data;
}

export async function cancelConsultation(
  consultationId: LawyerConsultation['id']
): Promise<void> {
  await apiPrivatePythonClient.post<LawyerConsultation>(
    `/lawyer/consultations/${consultationId}/cancel`
  );
}

export async function completeConsultation(
  consultationId: LawyerConsultation['id']
): Promise<void> {
  await apiPrivatePythonClient.post<LawyerConsultation>(
    `/lawyer/consultations/${consultationId}/complete`
  );
}
