import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pagination } from 'components/pagination';
import { useEffect, useState } from 'react';
import {
  DEFAULT_CONSULTATIONS_PAGE_SIZE,
  LawyerConsultation,
  completeConsultation,
  getLawyerConsultations
} from 'services/lawyerConsultation';

import ModalCancelConsultation from 'features/consultation/ModalCancelConsultation';

import { ConsultationStatus, ConsultationsFilter } from 'types/consultation';

import LawyerConsultationCard from '../lawyer/account/consultations/card/LawyerConsultationCard';

const LawyerToCompleteConsultations = () => {
  const queryClient = useQueryClient();
  const [toCompletePage, setToCompletePage] = useState(1);
  const [consultationId, setConsultationId] =
    useState<LawyerConsultation['id']>();

  const { mutate: completeConsultationMutate, isPending } = useMutation({
    mutationKey: ['lawyer-consultation-cancel', consultationId],
    mutationFn: (id: LawyerConsultation['id']) => completeConsultation(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lawyer-consultations-to-completed']
      });
    }
  });

  const {
    data: consultationsToCompleteResponse,
    refetch: refetchToConsultationsComplete
  } = useQuery({
    queryKey: ['lawyer-consultations-to-completed', toCompletePage],
    queryFn: () =>
      getLawyerConsultations({
        page: toCompletePage,
        completable: true,
        status: [ConsultationStatus.SCHEDULED]
      })
  });

  useEffect(() => {
    refetchToConsultationsComplete();
  }, [toCompletePage, refetchToConsultationsComplete]);

  const [isModalCancelConsultationOpen, setIsModalCancelConsultationOpen] =
    useState<boolean>(false);

  const handleCancelConsultation = (id: LawyerConsultation['id']) => {
    setConsultationId(id);
    setIsModalCancelConsultationOpen(true);
  };

  const handleCompleteConsultation = (id: LawyerConsultation['id']) => {
    completeConsultationMutate(id);
  };

  return (
    <div className="flex flex-col gap-6">
      {consultationsToCompleteResponse?.items.map((consultation) => (
        <LawyerConsultationCard
          key={consultation.id}
          status={ConsultationsFilter.TOCOMPLETE}
          consultation={consultation}
          onButtonClickConsultation={handleCancelConsultation}
          handleCompleteConsultation={handleCompleteConsultation}
          isCompleteConsultationLoading={isPending}
        />
      ))}
      {consultationId && (
        <ModalCancelConsultation
          consultationId={consultationId}
          isModalCancelConsultationOpen={isModalCancelConsultationOpen}
          setIsModalCancelConsultationOpen={setIsModalCancelConsultationOpen}
        />
      )}
      <Pagination
        onRefetch={(count) => {
          setToCompletePage(count);
        }}
        pageCount={
          (consultationsToCompleteResponse?.total || 1) /
          DEFAULT_CONSULTATIONS_PAGE_SIZE
        }
      />
    </div>
  );
};

export default LawyerToCompleteConsultations;
