import { SVGProps } from 'react';

export default function MagnifierIllustration({
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M73.7927 115.449C81.5582 130.211 99.2931 134.93 113.405 125.988C127.516 117.046 132.661 97.829 124.895 83.0665C117.13 68.3039 99.3946 63.5855 85.2831 72.5276C71.1715 81.4698 66.0271 100.686 73.7927 115.449Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M74.9949 114.688C82.3954 128.756 99.2965 133.253 112.745 124.731C126.193 116.209 131.095 97.8963 123.695 83.8278C116.294 69.7592 99.3932 65.2627 85.9451 73.7845C72.497 82.3062 67.5944 100.619 74.9949 114.688Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M86.9076 75.6169C91.5373 72.6832 96.613 71.4217 101.5 71.6411C109.812 72.0287 117.617 76.7172 121.941 84.9378"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M111.781 122.898C99.2934 130.811 83.6193 126.641 76.7473 113.577"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M80.0082 76.7083L79.0906 75.7887C79.0906 75.7887 72.8742 81.5432 70.4141 89.4904L71.8072 90.1087"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M65.6285 70.8108C65.6285 70.8108 65.2326 69.1068 64.2917 69.9823C63.3507 70.8578 60.3931 75.385 59.9741 76.8723C59.9741 76.8723 60.395 78.5605 61.3447 77.5747C61.3447 77.5747 63.146 73.6406 65.6285 70.8108Z"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M61.3437 77.5747C61.254 79.3072 62.8996 78.439 62.8996 78.439C63.5473 75.8642 67.1333 71.7067 67.1333 71.7067C67.1333 71.7067 67.1154 69.833 65.6025 70.8266"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M67.1201 71.6804C67.1201 71.6804 71.3458 74.4485 72.0042 74.9389C72.6627 75.4293 72.4174 75.7244 72.4174 75.7244C72.4174 75.7244 69.6386 79.894 68.5303 82.4814C68.5303 82.4814 68.6318 82.8011 67.7943 81.9703L62.8612 78.4286"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M72.4301 75.7508C73.958 74.4685 74.2902 76.8762 74.2902 76.8762C74.2902 76.8762 71.3473 80.4166 70.1027 83.8235C70.1027 83.8235 68.3307 84.3878 68.5293 82.4816"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M74.2907 76.8755C75.6023 77.0218 76.0568 77.8858 76.0568 77.8858C76.0568 77.8858 73.8082 79.7645 71.469 85.0868C71.469 85.0868 70.5791 84.9176 70.1171 83.849"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M76.985 78.0655L76.0436 77.8595"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M71.8564 85.8874L71.4545 85.0602"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M65.1819 69.8366L65.1868 69.275C65.1979 68.5349 64.8786 67.8646 64.3254 67.4472L20.7444 36.1056C20.7444 36.1056 18.4056 36.9244 16.1989 40.9757C13.9922 45.0269 14.4929 45.4078 14.4929 45.4078L58.3094 77.5776C58.6773 77.8331 59.1516 77.8467 59.527 77.6088L60.1026 77.2441"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
      <path
        d="M64.5347 68.6057C64.5347 68.6057 60.444 71.2327 59.1611 76.7235"
        stroke="black"
        strokeWidth="0.4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
