import { apiPrivatePythonClient } from './axios';

export type LawyerMember = {
  full_name: string;
  email: string;
  id: number;
  is_cse_member: boolean;
  can_book_consultation: boolean;
};

interface LawyerMembersPayload {
  pageSize?: number;
  search: string;
}

interface LawyerMembersResponse {
  total: number;
  items: LawyerMember[];
}

export async function getLawyerMembers({
  pageSize = 5,
  search
}: LawyerMembersPayload): Promise<LawyerMembersResponse> {
  const { data } = await apiPrivatePythonClient.get(`/lawyer/members`, {
    params: { page_size: pageSize, search }
  });

  return data;
}
