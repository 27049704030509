import { apiPrivatePythonClient } from './axios';

export type LawyerTag = {
  id: string;
  name: string;
};

interface LawyerTagsResponse {
  total: number;
  items: LawyerTag[];
}

interface LawyerTagsPayload {
  pageSize?: number;
  search?: string;
}

export async function getLawyerTags({
  pageSize = 5,
  search
}: LawyerTagsPayload): Promise<LawyerTagsResponse> {
  const { data } = await apiPrivatePythonClient.get(`/lawyer/tags`, {
    params: { page_size: pageSize, search }
  });

  return data;
}
