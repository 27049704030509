import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ScheduleConsultationPayload,
  createConsultation,
  rescheduleConsultation
} from 'services/consultation';

import {
  useGuardedSearchParams,
  useLocale,
  useUnguardedSearchParams
} from 'hooks';

import { BookingStep } from 'pages/consultation/ConsultationBookingPage';

import { Button } from 'features/shared/buttons';
import { Toast } from 'features/shared/toasts';

import { formatConsultationTimeSlotDateToIntl } from 'utils/date';

import { SetState } from 'types/core';
import { ConsultationSlot } from 'types/lawyer';

export default function ConsultationBookingSummary({
  slot,
  setBookingStep
}: {
  slot: ConsultationSlot;
  setBookingStep: SetState<BookingStep>;
}) {
  const { t } = useTranslation('customer');
  const queryClient = useQueryClient();
  const { locale } = useLocale();
  const navigate = useNavigate();
  const consultationId = useUnguardedSearchParams({ param: 'consultationId' });
  const questionId = useGuardedSearchParams({ param: 'questionId' });
  const { mutate, isPending: isCreateConsultationLoading } = useMutation({
    mutationFn: (formData: ScheduleConsultationPayload) =>
      createConsultation(formData),
    onError: (error: any) => {
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.code === 'slot_not_available'
      ) {
        toast.custom(
          <Toast
            type="error"
            title={t('general.error')}
            message={t('account.consultations.schedule.error.slotNotAvailable')}
          />,
          { position: 'bottom-left', duration: 3000 }
        );

        return;
      }

      toast.custom(
        <Toast
          type="error"
          title={t('general.error')}
          message={t('general.error')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['member-question', Number(questionId)],
        refetchType: 'all'
      });
      toast.custom(
        <Toast
          type="success"
          title={t('general.success')}
          message={t('account.consultations.booking.success')}
        />,
        { position: 'bottom-left', duration: 3000 }
      );
      navigate(`/questions/${questionId}`);
    }
  });

  const {
    mutateAsync: mutationRescheduleAsync,
    isPending: isRescheduleConsultationLoading
  } = useMutation({
    mutationFn: ({ startAt }: { startAt: Date }) =>
      rescheduleConsultation(Number(consultationId), startAt),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['consultations'] });
    }
  });

  const formattedConsultationSlotToIntl = useMemo(
    () => formatConsultationTimeSlotDateToIntl(slot.startAt, locale),
    [locale, slot.startAt]
  );

  async function onSubmitScheduleConsultation() {
    mutate({
      questionId: Number(questionId),
      startAt: slot.startAt
    });
  }

  async function handleRescheduleConsultationBooking() {
    const cId = Number(consultationId);
    await mutationRescheduleAsync(
      {
        startAt: slot.startAt
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({
            queryKey: ['member-consultation', cId],
            refetchType: 'inactive'
          });
          toast.custom(
            <Toast
              type="success"
              title={t('general.success')}
              message={t('account.consultations.booking.success')}
            />,
            { position: 'bottom-left', duration: 3000 }
          );
          navigate(`/questions/${questionId}`);
        }
      }
    );
  }

  const onStepBack = () => setBookingStep(BookingStep.Scheduling);

  return (
    <div className="mt-6 max-w-[720px]">
      <div className="p-8 pb-14 w-full h-auto bg-white rounded shadow-xl mb-6">
        <div className="flex flex-col w-full">
          <h2 className="text-black font-sans font-bold text-xl leading-[24px] mb-6">
            {t('account.consultations.booking.payment.title')}
          </h2>
          <div className="border border-[#CCCCCC] w-full p-6 flex flex-col rounded">
            <div className="flex flex-row justify-between items-center mb-4">
              <p className="font-sans font-bold text-lg">
                {formattedConsultationSlotToIntl}
              </p>
            </div>
            <span className="flex font-sans text-base text-black">
              {t('account.consultations.generalOffer')}
            </span>
          </div>
        </div>
      </div>

      <div className="mb-5">
        <p className="text-gray-700 text-sm text-center">
          {t('account.consultations.booking.scheduling.agreement')}
        </p>
      </div>
      <div className="flex w-full gap-x-[14px]">
        <Button
          variant="secondary"
          size="large"
          label={t('account.consultations.booking.payment.back')}
          onClick={onStepBack}
        />
        <Button
          onClick={() => {
            if (consultationId) return handleRescheduleConsultationBooking();
            return onSubmitScheduleConsultation();
          }}
          isLoading={
            isCreateConsultationLoading || isRescheduleConsultationLoading
          }
          fullWidth
          label={t('account.consultations.confirm')}
          variant="primary"
          size="large"
        />
      </div>
    </div>
  );
}
