import { Fade } from 'components/transition';
import { range } from 'lodash';

interface LawyerRequestsListSkeletonProps {
  number?: number;
}

const LawyerMemberHistoryQuestionsSkeleton = ({
  number = 4
}: LawyerRequestsListSkeletonProps) => (
  <Fade>
    <div className="flex flex-col gap-6 my-6">
      {range(1, number).map((value) => (
        <div key={value} className="h-12 w-full bg-gray-300 rounded" />
      ))}
    </div>
  </Fade>
);

export default LawyerMemberHistoryQuestionsSkeleton;
