import { Title } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'features/shared/body';
import { Button } from 'features/shared/buttons';

export interface AnswerVoteUnsatisfiedFormProps {
  isLoading: boolean;
  onSubmitComment: (reason: string) => void;
}

const RateAnswerComment = ({
  isLoading,
  onSubmitComment
}: AnswerVoteUnsatisfiedFormProps) => {
  const { t } = useTranslation('customer');
  const [reasonText, setReasonText] = useState<string>('');

  return (
    <div>
      <Title variant="h4" component="h3" gutterBottom>
        {t('account.question.vote.unsatisfied.title')}
      </Title>
      <Paragraph className="text-sm text-left font-medium text-gray-700">
        {t('account.question.vote.unsatisfied.description')}
      </Paragraph>
      <div className="mt-2 w-full">
        <textarea
          rows={3}
          name="question"
          id="question"
          minLength={20}
          maxLength={1200}
          value={reasonText}
          onChange={(e) => setReasonText(e.target.value)}
          className="w-full placeholder:text-gray-700 text-sm md:text-base block focus:border-purple-900 focus:drop-shadow-input focus:ring-0 focus:ring-offset-0 focus:shadow-purple-900 focus:outline-none py-3 px-4 rounded"
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          submit
          variant="primary"
          size="small"
          label={t('general.send')}
          onClick={() => onSubmitComment(reasonText)}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default RateAnswerComment;
