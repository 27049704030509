import { apiPrivatePythonClient } from './axios';

export const SKIP_ONBOARDING_COMPLETION = 'skipOnboardingCompletion';

type MemberOnboardingStatus =
  | 'profile_data_missing'
  | 'not_started'
  | 'completed';

export type AuthenticatedMemberInfo = {
  id: number;
  email: string;
  secondary_email: string | null;
  first_name: string | null;
  last_name: string | null;
  phone_cellphone: string | null;
  customer_id: string | null;
  is_active_cse_member: boolean;
  is_admin: boolean;
  onboarding_status: MemberOnboardingStatus;
  can_ask_question: boolean;
  can_book_consultation: boolean;
  has_labor_law_access: boolean;
};

export async function getAuthenticatedMemberInfo(): Promise<AuthenticatedMemberInfo> {
  const { data } = await apiPrivatePythonClient.get(`/member/members/me`);

  return data;
}

export async function updatePassword(newPassword: string): Promise<void> {
  await apiPrivatePythonClient.put(`/member/members/me/password`, {
    password: newPassword
  });
}

export type MemberUpdate = {
  firstName: string;
  lastName: string;
  phoneCellphone: string;
  secondaryEmail: string | null;
};

export async function updateMember(memberUpdate: MemberUpdate): Promise<void> {
  await apiPrivatePythonClient.put(`/member/members/me`, {
    first_name: memberUpdate.firstName,
    last_name: memberUpdate.lastName,
    phone_cellphone: memberUpdate.phoneCellphone,
    secondary_email: memberUpdate.secondaryEmail
  });
}

export type MemberSubscriptionInfo = {
  id: number;
  status: 'active' | 'active_terminated' | 'terminated';
  end_at: string | null;
  product_name: string;
  product_max_total_questions: number | null;
  product_max_total_consultations: number | null;
};

export async function getAuthenticatedMemberSubscriptionInfo(): Promise<MemberSubscriptionInfo> {
  const { data } = await apiPrivatePythonClient.get(
    `/member/members/me/subscription`
  );
  return data;
}

export type OnboardingConsent = {
  isSubscribedToCommunications: boolean;
  hasAcceptedTermsOfService: boolean;
};

export async function sendOnboardingConsent(
  onboardingConsent: OnboardingConsent
): Promise<void> {
  await apiPrivatePythonClient.post(`/member/onboarding-consents`, {
    is_subscribed_to_communications:
      onboardingConsent.isSubscribedToCommunications,
    has_accepted_terms_of_service: onboardingConsent.hasAcceptedTermsOfService
  });
}

export interface PersonalContext {
  hasChildren: boolean | null;
  isHomeOwner: boolean | null;
  isMarried: boolean | null;
  themesOfInterest: string[];
}

export async function sendPersonalContext(
  personalContext: PersonalContext
): Promise<void> {
  await apiPrivatePythonClient.put(`/member/members/me/personal-context`, {
    has_children: personalContext.hasChildren,
    is_home_owner: personalContext.isHomeOwner,
    is_married: personalContext.isMarried,
    themes_of_interest: personalContext.themesOfInterest
  });
}
