import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MEMBER_DEFAULT_REDIRECT_URL } from 'routes/constants';
import {
  AuthLoginPayload,
  loginByPasswordOrMagicLink
} from 'services/authentication/login';

import { useUnguardedSearchParams } from 'hooks';

import { useAuth } from 'context';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import { LayoutCard } from 'features/shared/layout';

import { LoginMagicLinkLink } from './components';
import { LoginPasswordFormInputs, loginPasswordSchema } from './validation';

const PasswordLogin = () => {
  const handleError = useErrorHandler();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('customer');
  const { state } = useLocation();
  const { t: tForm } = useTranslation('form');
  const { setRefreshToken, setAccessToken } = useAuth();
  const newPassword =
    useUnguardedSearchParams({ param: 'newPassword' }) === 'true';

  const defaultEmail = state?.email || '';
  const { mutate, isPending } = useMutation({
    mutationFn: (data: AuthLoginPayload) => loginByPasswordOrMagicLink(data),
    onSuccess: (data) => {
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
      navigate(searchParams.get('origin') || MEMBER_DEFAULT_REDIRECT_URL);
    }
  });

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors }
  } = useForm<LoginPasswordFormInputs>({
    defaultValues: { email: defaultEmail },
    resolver: yupResolver(loginPasswordSchema(tForm))
  });

  const onSubmit: SubmitHandler<LoginPasswordFormInputs> = async (formData) => {
    const { email, password } = formData;
    mutate(
      { email, password },
      {
        onError: (error: any) => {
          const isUnauthorizedError = error?.response?.status === 401;

          if (isUnauthorizedError) {
            setError('email', {
              message: error?.response?.data.message,
              type: 'focus'
            });
          } else {
            handleError(error);
          }
        }
      }
    );
  };

  return (
    <LayoutCard
      title={t('login.title.default')}
      body={
        <div className="w-full sm:px-8">
          <p className="text-base font-medium text-gray-800 pb-8">
            {t('login.description.password')}
          </p>
          {newPassword && <div>{t('password.reset.success.paragraph')}</div>}
          <div className="pb-4">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex w-full flex-col gap-4"
            >
              <TextInputGroup
                type="email"
                fullWidth
                autofocus={!defaultEmail}
                placeholder={tForm('placeholder.email')}
                label={tForm('label.email')}
                name="email"
                register={register}
                error={errors.email}
              />
              <TextInputGroup
                type="password"
                fullWidth
                placeholder="**********"
                autofocus={!!defaultEmail}
                label={tForm('label.password')}
                name="password"
                register={register}
                error={errors.password}
              />
              <a
                className="font-medium text-left -mt-4 hover:underline text-purple-900 mb-1"
                href="/password/request"
              >
                {t('password.forgotten')}
              </a>
              <Button
                isLoading={isPending}
                fullWidth
                submit
                size="medium"
                variant="primary"
                label={t('general.continue')}
              />
            </form>
          </div>
          <LoginMagicLinkLink control={control} />
        </div>
      }
    />
  );
};

export default PasswordLogin;
